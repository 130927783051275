import {
  DashboardWidget,
  WidgetType,
} from '../../utils/types/visualDashboard.type';

export enum VisualDashboardType {
  Fund = 'FUND_PAGE',
  CapitalAccounts = 'CAPITAL_ACCOUNTS_PAGE',
}

export enum VisualDashboardViewKey {
  Fund = 'FUNDS_VISUALIZATIONS_VIEW_KEY',
  CapitalAccounts = 'CAPITAL_ACCOUNTS_VISUALIZATIONS_VIEW_KEY',
}

export const defaultFundPageDashboardWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'totalFunds',
    title: 'Total Funds',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'capitalCommitted',
    title: 'Capital Committed',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'funded',
    title: 'Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'unfunded',
    title: 'Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'distributions',
    title: 'Distributions',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'nav',
    title: 'Value',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 6,
    name: 'rvpi',
    title: 'RVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 7,
    name: 'dpi',
    title: 'DPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 8,
    name: 'tvpi',
    title: 'TVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 9,
    name: 'percentRemaining',
    title: '% Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 10,
    name: 'percentCalled',
    title: '% Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 11,
    name: 'moic',
    title: 'MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },

  {
    index: 12,
    name: 'committedByFund',
    title: 'Committed By Fund',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 13,
    name: 'totalValueDistributions',
    title: 'Total Value and Distributions',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 14,
    name: 'committedToPaid',
    title: 'Committed to Paid',
    visible: true,
    widgetType: WidgetType.Charts,
  },

  {
    index: 15,
    name: 'entityTypeByFund',
    title: 'Entity Type by Fund',
    visible: true,
    widgetType: WidgetType.EntityTypeByFund,
  },
];

export const defaultCapitalAccountsPageDashboardWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'capitalCommitted',
    title: 'Capital Committed',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'funded',
    title: 'Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'unfunded',
    title: 'Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'distributions',
    title: 'Distributions',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'nav',
    title: 'Value',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'rvpi',
    title: 'RVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 6,
    name: 'dpi',
    title: 'DPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 7,
    name: 'tvpi',
    title: 'TVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 8,
    name: 'percentRemaining',
    title: '% Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 9,
    name: 'percentCalled',
    title: '% Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 10,
    name: 'moic',
    title: 'MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },

  {
    index: 11,
    name: 'committedByFund',
    title: 'Committed By Fund',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 12,
    name: 'totalValueDistributions',
    title: 'Total Value and Distributions',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 13,
    name: 'committedToPaid',
    title: 'Committed to Paid',
    visible: true,
    widgetType: WidgetType.Charts,
  },
];
