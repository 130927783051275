import { FormControlLabel, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import { DataGrid, ProgressModal } from '../../../components';
import { DataWrapperBox } from '../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import { DateRangePicker } from '../../../components/DateRangePicker/DateRangePicker';
import MultiSelectFilters from '../../../components/MultiSelectFilters/MultiSelectFilters';
import SingleSelect from '../../../components/SingleSelect/SingleSelect';
import Switch from '../../../components/Switch/Switch';
import { ExportButton as ExportButtonSOI } from '../../fund/scheduleOfInvestments/ExportButton/ExportButton';
import { useScheduleOfInvestments } from '../../fund/scheduleOfInvestments/ScheduleOfInvestments.hooks';
import ExportButton from './ExportButton';
import { ReportDetails } from './glReports/glReportDetails/ReportDetails';
import { BalanceSheet } from './glReports/glReportList/BalanceSheet';
import { BalanceSheetCustom } from './glReports/glReportList/BalanceSheetCustom';
import { GeneralLedgerReport } from './glReports/glReportList/GeneralLedgerReport';
import { IncomeStatement } from './glReports/glReportList/IncomeStatement';
import { IncomeStatementCustom } from './glReports/glReportList/IncomeStatementCustom';
import { TrialBalance } from './glReports/glReportList/TrialBalance';
import { GlViews, useGlReportList } from './NewGlReportList.hooks';
import {
  GLReportsActionsContainer,
  NoDataMessageTypography,
  StyledFilterBox,
  StyledFilterSpacerBox,
} from './ReportList.styles';
import {
  ButtonBox,
  MainContainer,
  ReportSelectorButtonBox,
  StyledDateFilterBox,
} from './ReportList.styles';

export const GlReportList: React.FC = () => {
  const {
    isLoading,
    setIsLoading,
    reportView,
    reportViewOptions,
    handleOptionSelection,
    selectedAccounts,
    selectedAttributes,
    selectedFunds,
    selectedLedgers,
    selectedStatus,
    handleFundFilterChange,
    fundsFilter,
    handleLedgerFilterChange,
    filteredLedgers,
    handleAccountFilterChange,
    filteredAccounts,
    handleAttributeFilterChange,
    filteredAttributes,
    handleStatusFilterChange,
    statusFilter,
    dateRange,
    handleDateRangeChange,
    attributeFilterDisabled,
    suppressIfZero,
    handleSuppressIfZeroChange,
    validFilters,
    handleRunReportButtonClick,
    noDataMessage,
    invalidSelectedLedgers,
    runReportView,
    setMainReportListData,
    mainReportListData,
    reportParams,
    funds,
    attributes,
    viewFiltersState,
    handleValueLinkButtonClick,
    summaryRowClicked,
    level1Clicked,
    zeroSumStartBalance,
    handleCloseReportDetails,
    reportDetailsApiParams,
    gridData,
    exportName,
    handleDataGridChange,
    netRow,
    retainedRow,
    soiView,
    generalAttributes,
    handleGlReportData,
    fetchGlReport,
    setFetchGlReport,
    isLoadingList,
    soiGridData,
    handleColumnOrderChange,
    soiDateFilter,
    handleSoiDateChange,
  } = useGlReportList();

  let reportViewComponent;

  switch (runReportView) {
    case GlViews.trialBalance:
      reportViewComponent = (
        <TrialBalance
          params={reportParams!}
          setMainReportListData={setMainReportListData}
          reportView={reportView}
          suppressIfZero={suppressIfZero}
          dateRange={dateRange}
          funds={funds}
          attributes={attributes}
          viewFilters={viewFiltersState}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleValueLinkButtonClick={handleValueLinkButtonClick}
          onDataGridChange={handleDataGridChange}
          handleDateRangeChange={handleDateRangeChange}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      );
      break;
    case GlViews.balanceSheet:
      reportViewComponent = (
        <BalanceSheet
          params={reportParams!}
          setMainReportListData={setMainReportListData}
          reportView={reportView}
          suppressIfZero={suppressIfZero}
          dateRange={dateRange}
          funds={funds}
          attributes={attributes}
          viewFilters={viewFiltersState}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleValueLinkButtonClick={handleValueLinkButtonClick}
          onDataGridChange={handleDataGridChange}
          handleDateRangeChange={handleDateRangeChange}
          generalAttributes={generalAttributes}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      );
      break;
    case GlViews.balanceSheetFsMapping:
      reportViewComponent = (
        <BalanceSheetCustom
          params={reportParams!}
          setMainReportListData={setMainReportListData}
          reportView={reportView}
          suppressIfZero={suppressIfZero}
          dateRange={dateRange}
          funds={funds}
          attributes={attributes}
          viewFilters={viewFiltersState}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleValueLinkButtonClick={handleValueLinkButtonClick}
          onDataGridChange={handleDataGridChange}
          handleDateRangeChange={handleDateRangeChange}
          generalAttributes={generalAttributes}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      );
      break;
    case GlViews.incomeStatement:
      reportViewComponent = (
        <IncomeStatement
          params={reportParams!}
          setMainReportListData={setMainReportListData}
          reportView={reportView}
          suppressIfZero={suppressIfZero}
          dateRange={dateRange}
          funds={funds}
          attributes={attributes}
          viewFilters={viewFiltersState}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleValueLinkButtonClick={handleValueLinkButtonClick}
          onDataGridChange={handleDataGridChange}
          handleDateRangeChange={handleDateRangeChange}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      );
      break;
    case GlViews.incomeStatementFsMapping:
      reportViewComponent = (
        <IncomeStatementCustom
          params={reportParams!}
          setMainReportListData={setMainReportListData}
          reportView={reportView}
          suppressIfZero={suppressIfZero}
          dateRange={dateRange}
          funds={funds}
          attributes={attributes}
          viewFilters={viewFiltersState}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleValueLinkButtonClick={handleValueLinkButtonClick}
          onDataGridChange={handleDataGridChange}
          handleDateRangeChange={handleDateRangeChange}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      );
      break;
    case GlViews.unrealizedSOI:
      reportViewComponent = (
        <DataGrid
          id="soi_data_grid"
          dataList={soiGridData.soiGridRows || []}
          headerList={soiGridData.dataGridColDefs}
          noDataMessage={isLoadingList ? 'Fetching Data...' : undefined}
          minHeight="80vh"
          autoHeight={false}
          activeHeaderFields={soiGridData.dataGridColDefs.length}
          checkboxSelection={false}
          onColumnOrderChange={(colDefs) => {
            handleColumnOrderChange(colDefs.oldIndex, colDefs.targetIndex);
          }}
        />
      );
      break;
    case GlViews.realizedSOI:
      reportViewComponent = (
        <DataGrid
          id="soi_data_grid"
          dataList={soiGridData.soiGridRows || []}
          headerList={soiGridData.dataGridColDefs}
          noDataMessage={isLoadingList ? 'Fetching Data...' : undefined}
          minHeight="80vh"
          autoHeight={false}
          activeHeaderFields={soiGridData.dataGridColDefs.length}
          checkboxSelection={false}
          onColumnOrderChange={(colDefs) => {
            handleColumnOrderChange(colDefs.oldIndex, colDefs.targetIndex);
          }}
        />
      );
      break;
    case GlViews.generalLedgerReport:
      reportViewComponent = (
        <GeneralLedgerReport
          params={reportParams!}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          suppressIfZero={suppressIfZero}
          attributes={attributes}
          funds={funds}
          onDataGridChange={handleGlReportData}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      );
      break;
    default:
      reportViewComponent = null;
  }

  return (
    <>
      <MainContainer id="report_list_main" role="main">
        <HorizontalBox fullWidth noPadding>
          <HorizontalBox noPadding>
            <Typography variant="h1">Reports</Typography>
            <ReportSelectorButtonBox>
              <SingleSelect
                id="selecter_view"
                value={reportView}
                optionList={reportViewOptions}
                label={'Report View'}
                handleOptionSelection={handleOptionSelection}
                width="300px"
              />
              {soiView ? (
                <StyledDateFilterBox>
                  <DatePicker
                    label="Date"
                    renderInput={(params) => (
                      <TextField {...params} size="small" id="filter_date" />
                    )}
                    onChange={(value) => {
                      handleSoiDateChange(value);
                    }}
                    value={soiDateFilter}
                  />
                </StyledDateFilterBox>
              ) : (
                ''
              )}
            </ReportSelectorButtonBox>
          </HorizontalBox>
          <ButtonBox>
            {soiView ? (
              <ExportButtonSOI
                id="btn_export_wrapper"
                hidden={!(soiGridData?.soiGridRows?.length > 0)}
                headers={soiGridData?.dataGridColDefs || []}
                date={soiDateFilter}
                data={soiGridData?.soiGridRows || []}
              />
            ) : (
              <ExportButton
                id="btn_export_wrapper"
                hidden={!gridData?.gridRows?.length}
                headers={gridData?.dataGridColDefs || []}
                data={gridData?.gridRows || []}
                fileName={exportName}
                reportView={reportView}
              />
            )}
          </ButtonBox>
        </HorizontalBox>
        <div /> {/* Hack spacer */}
        <DataWrapperBox gapSize={0}>
          {isLoading && (
            <ProgressModal
              id="gl_report_info_loading"
              showProgress={isLoading}
            />
          )}
          {isLoadingList && (
            <ProgressModal
              id="gl_report_info_loading"
              showProgress={isLoadingList}
            />
          )}
          <HorizontalBox id="report_filter_box" fullWidth noPadding>
            {!soiView ? (
              <GLReportsActionsContainer>
                <StyledFilterBox>
                  <MultiSelectFilters
                    size="small"
                    id={'fund_filter'}
                    value={selectedFunds}
                    onFilter={handleFundFilterChange}
                    label={`Fund (${selectedFunds.length})`}
                    listData={fundsFilter as []}
                    filterName={'fund_filter'}
                  />
                </StyledFilterBox>
                <StyledFilterBox>
                  <MultiSelectFilters
                    size="small"
                    id={'ledger_filter'}
                    value={selectedLedgers}
                    onFilter={handleLedgerFilterChange}
                    label={`GL Name (${selectedLedgers.length})`}
                    listData={filteredLedgers as []}
                    filterName={'ledger_filter'}
                  />
                </StyledFilterBox>
                <StyledFilterBox>
                  <MultiSelectFilters
                    size="small"
                    id={'account_filter'}
                    value={selectedAccounts}
                    onFilter={handleAccountFilterChange}
                    label={`Account (${selectedAccounts.length}/${filteredAccounts.length})`}
                    listData={filteredAccounts as []}
                    filterName={'account_filter'}
                  />
                </StyledFilterBox>
                {attributeFilterDisabled ? (
                  ''
                ) : (
                  <StyledFilterBox>
                    <MultiSelectFilters
                      size="small"
                      id={'attribute_filter'}
                      value={selectedAttributes}
                      onFilter={handleAttributeFilterChange}
                      label={`Account Attributes (${selectedAttributes.length})`}
                      listData={filteredAttributes as []}
                      filterName={'attribute_filter'}
                    />
                  </StyledFilterBox>
                )}
                <StyledFilterBox>
                  <MultiSelectFilters
                    size="small"
                    id={'status_filter'}
                    value={selectedStatus}
                    onFilter={handleStatusFilterChange}
                    label={`Status (${selectedStatus.length})`}
                    listData={statusFilter as []}
                    filterName={'status_filter'}
                  />
                </StyledFilterBox>
                <StyledFilterSpacerBox />
                <StyledFilterBox>
                  <DateRangePicker
                    disabled={false}
                    dateRange={dateRange}
                    onChange={handleDateRangeChange}
                  />
                </StyledFilterBox>
                <StyledFilterBox>
                  <FormControlLabel
                    id="chk_supress_if_zero"
                    label="Suppress 0 Lines"
                    control={
                      <Switch
                        id="switch_supress_if_zero"
                        checked={suppressIfZero}
                        label="Suppress 0 Lines"
                        onChange={
                          !validFilters ? null : handleSuppressIfZeroChange
                        }
                      />
                    }
                    disabled={!validFilters}
                  />
                </StyledFilterBox>
                <StyledFilterSpacerBox />
                <StyledFilterBox>
                  <Button
                    id="run_report_button"
                    text="Apply"
                    color="primary"
                    size="small"
                    disabled={!validFilters}
                    onClick={handleRunReportButtonClick}
                  />
                </StyledFilterBox>
              </GLReportsActionsContainer>
            ) : (
              ''
            )}
          </HorizontalBox>
          {!runReportView && !validFilters ? '' : reportViewComponent}
          {
            // Re-write of how Report Grid component is passed to individual report components pending (12/11/23)
            !noDataMessage || soiView ? (
              '' //reportGridComponent
            ) : (
              <NoDataMessageTypography
                color={invalidSelectedLedgers ? 'Red' : ''}
              >
                {noDataMessage}
              </NoDataMessageTypography>
            )
          }
        </DataWrapperBox>
        <ReportDetails
          open={!!reportDetailsApiParams}
          reportParams={reportDetailsApiParams}
          onClose={handleCloseReportDetails}
          reportView={reportView}
          summaryRowClicked={summaryRowClicked}
          zeroSumStartBalance={zeroSumStartBalance}
          level1Clicked={level1Clicked}
          dateRange={dateRange}
          netRow={netRow}
          retainedRow={retainedRow}
          fetchGlReport={fetchGlReport}
          setFetchGlReport={setFetchGlReport}
        />
      </MainContainer>
    </>
  );
};
