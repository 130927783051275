import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import IconDrag from '../../../assets/images/icons/icon_drag_column.svg';
import IconFilter from '../../../assets/images/icons/icon_filter.svg';
import { FilterSelectionType } from '../../../utils/types/filter.type';
import { DataGridColDef } from '../../../utils/types/listItems';
import ArrowPopover from '../../ArrowPopover/ArrowPopover';
import ImgIcon from '../../ImgIcon/ImgIcon';
import Filter from '../Filter/Filter';
import FilterDate from '../Filter/FilterDate/FilterDate';
import FilterGroup from '../Filter/FilterGroup/FilterGroup';
import FilterSearch from '../Filter/FilterSearch';
import FilterStatus from '../Filter/FilterStatus/FilterStatus';
import { PopoverFilter } from '../Filter/PopoverFilter.style';
import PopoverFilterList from '../Filter/PopoverFilterList';
import SplitFilter from '../Filter/SplitFilter';
import { StyledCell } from './ColumnHeaderCell.style';

interface Props {
  item: DataGridColDef;
  handleFilter: (
    filter: any,
    selected: any[] | any,
    selectionType?: FilterSelectionType
  ) => void;
}

const ColumnHeaderCell: React.FC<Props> = ({
  item,
  handleFilter,
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = (): void => {
    setAnchorEl(null);
  };

  const onFilter = (
    filter: any,
    selectedOptions: Array<any>,
    selectionType?: FilterSelectionType
  ) => {
    handleOnClose();
    handleFilter(filter, selectedOptions, selectionType);
  };

  const RenderFilter = (columnType: string = 'string') => {
    switch (columnType) {
      case 'status':
        return (
          <FilterStatus
            handleFilter={onFilter}
            options={item.inlineFilterOptions}
            selectedOptions={item.inlineFilterSelected}
            idField={item.inlineFilterIDField ?? 'id'}
            labelField={item.inlineFilterLabelField ?? 'name'}
            filterName={item.inlineFilterName}
          />
        );
      case 'string':
        if (item.inlineSplitFilters) {
          return (
            <SplitFilter
              splitFilters={item.inlineSplitFilters}
              handleFilter={handleFilter}
            />
          );
        } else if (item.inlineFilterType === 'search') {
          return (
            <FilterSearch
              handleFilter={onFilter}
              filterName={item.inlineFilterName}
              selectedOptions={item.inlineFilterSelected}
            />
          );
        } else if (item.inlinePopoverFilters) {
          return (
            <PopoverFilterList
              options={item.inlinePopoverFilters}
              filterName={item.inlineFilterName}
              selectedOption={item.inlineToggleSwitch}
              handleFilter={(...args) => {
                handleFilter(...args);
                handleOnClose();
              }}
            />
          );
        }
        return (
          <Filter
            handleFilter={onFilter}
            options={item.inlineFilterOptions}
            selectedOptions={item.inlineFilterSelected}
            idField={item.inlineFilterIDField ?? 'id'}
            labelField={item.inlineFilterLabelField ?? 'name'}
            filterName={item.inlineFilterName}
            singleSelect={item.singleSelectFilter}
            emptySelectionOnClear={item.emptySelectionOnClear}
            optionFormComponent={item.optionFormComponent}
            scrollContainerStyle={item.scrollContainerStyle}
            showFilterAll={item.showFilterAll}
            shouldOptionReturnValue={item.shouldOptionReturnValue}
          />
        );
      case 'date':
        return (
          <FilterDate
            handleFilter={onFilter}
            options={item.inlineFilterOptions}
            selectedOptions={item.inlineFilterSelected}
            idField={item.inlineFilterIDField ?? 'id'}
            labelField={item.inlineFilterLabelField ?? 'name'}
            filterName={item.inlineFilterName}
          />
        );
      case 'chips':
        if (item.inlineFilterType === 'grouped') {
          return (
            <FilterGroup
              handleFilter={onFilter}
              options={item.inlineFilterOptions}
              selectedOptions={item.inlineFilterSelected}
              idField={item.inlineFilterIDField ?? 'id'}
              labelField={item.inlineFilterLabelField ?? 'name'}
              filterName={item.inlineFilterName}
            />
          );
        }
        return (
          <Filter
            handleFilter={onFilter}
            options={item.inlineFilterOptions}
            selectedOptions={item.inlineFilterSelected}
            idField={item.inlineFilterIDField ?? 'id'}
            labelField={item.inlineFilterLabelField ?? 'name'}
            filterName={item.inlineFilterName}
            emptySelectionOnClear={item.emptySelectionOnClear}
          />
        );
      default:
        return (
          <Filter
            handleFilter={onFilter}
            options={item.inlineFilterOptions}
            selectedOptions={item.inlineFilterSelected}
            idField={item.inlineFilterIDField ?? 'id'}
            labelField={item.inlineFilterLabelField ?? 'name'}
            filterName={item.inlineFilterName}
            emptySelectionOnClear={item.emptySelectionOnClear}
          />
        );
    }
  };

  return (
    <>
      <StyledCell>
        <ImgIcon icon={IconDrag} />
        <Typography variant="subtitle1">{item.headerName}</Typography>
        <Box>
          {item?.inlineFilter && (
            <Box
              id={`column-header-filter-${item.index}`}
              onClick={handleOnClick}
            >
              <ImgIcon useCursorPointer icon={IconFilter} />
            </Box>
          )}
        </Box>
      </StyledCell>
      {item?.inlineFilter && item?.inlineFilterName && !item.arrowPopover && (
        <PopoverFilter
          id={`column_header_cell_popover${item.index}`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {RenderFilter(item.type)}
        </PopoverFilter>
      )}
      {item.arrowPopover && (
        <ArrowPopover
          id={`column_header_cell_arrow_popover${item.index}`}
          showPopover={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleOnPopoverClose={handleOnClose}
          content={RenderFilter(item.type)}
          verticalOrigin="bottom"
          orientation="left"
          transformOrigin="top"
          arrowLeft="left"
        />
      )}
    </>
  );
};

export default ColumnHeaderCell;
