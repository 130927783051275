import { ListItem } from '../../../utils/types/listItems';
import { CategoryFieldDef, ValueFieldDef } from './ScheduleOfInvestments.types';

const defaultCategoryFieldOrder: CategoryFieldDef[] = [
  {
    name: 'fund',
    headerName: 'Fund',
    visible: true,
    order: 1,
  },
  {
    name: 'country',
    headerName: 'Country',
    visible: false,
    order: 2,
  },
  {
    name: 'industrySector',
    headerName: 'Industry/Sector',
    visible: false,
    order: 3,
  },
  {
    name: 'companyName',
    headerName: 'Portfolio Company Name',
    visible: true,
    order: 4,
  },
  {
    name: 'securityType',
    headerName: 'Security Type',
    visible: true,
    order: 5,
  },
  {
    name: 'security',
    headerName: 'Security',
    visible: true,
    order: 6,
  },
  {
    name: 'holdingStatus',
    headerName: 'Holding Status',
    visible: false,
    order: 7,
  },
  {
    name: 'isPublic',
    headerName: 'Public/Private',
    visible: false,
    order: 8,
  },
];

export const defaultDataFieldOrder: ValueFieldDef[] = [
  {
    name: 'shares',
    headerName: 'Shares',
    visible: true,
    order: 1,
    numberFormat: 'Decimal-use-comas.2',
  },
  {
    name: 'cost',
    headerName: 'Cost',
    visible: true,
    order: 2,
    numberFormat: 'Currency.0',
  },
  {
    name: 'fairMarketValue',
    headerName: 'Fair Market Value',
    visible: true,
    order: 3,
    numberFormat: 'Currency.0',
  },
  {
    name: 'unrealizedGainLoss',
    headerName: 'Unrealized Gain/Loss',
    visible: true,
    order: 4,
    numberFormat: 'Currency.0',
  },
  {
    name: 'realizedValue',
    headerName: 'Realized Value',
    visible: true,
    order: 5,
    numberFormat: 'Currency.0',
  },
  {
    name: 'realizedGainLoss',
    headerName: 'Realized Gain/Loss',
    visible: true,
    order: 6,
    numberFormat: 'Currency.0',
  },
  {
    name: 'totalGainLoss',
    headerName: 'Total Gain/Loss',
    visible: true,
    order: 7,
    numberFormat: 'Currency.0',
  },
  {
    name: 'costPerQuantity',
    headerName: 'Cost per Share',
    visible: true,
    order: 8,
    numberFormat: 'Currency.2',
  },
  {
    name: 'valuePerQuantity',
    headerName: 'Value per Share',
    visible: true,
    order: 9,
    numberFormat: 'Currency.2',
  },
  {
    name: 'initialInvestmentDate',
    headerName: 'Initial Investment Date',
    visible: true,
    order: 10,
    numberFormat: 'String',
  },
  {
    name: 'latestValuationDate',
    headerName: 'Latest Valuation Date',
    visible: true,
    order: 11,
    numberFormat: 'String',
  },
  {
    name: 'moic',
    headerName: 'MOIC',
    visible: false,
    order: 12,
    numberFormat: 'String',
  },
];

export const defaultUnrealizedColumnOrder: ValueFieldDef[] = [
  {
    name: 'shares',
    headerName: 'Shares',
    visible: true,
    order: 1,
    numberFormat: 'Decimal-use-comas.2',
  },
  {
    name: 'cost',
    headerName: 'Cost',
    visible: true,
    order: 2,
    numberFormat: 'Currency.0',
  },
  {
    name: 'fairMarketValue',
    headerName: 'Fair Market Value',
    visible: true,
    order: 3,
    numberFormat: 'Currency.0',
  },
  {
    name: 'unrealizedGainLoss',
    headerName: 'Unrealized Gain/Loss',
    visible: true,
    order: 4,
    numberFormat: 'Currency.0',
  },
  {
    name: 'moic',
    headerName: 'MOIC',
    visible: false,
    order: 5,
    numberFormat: 'String',
  },
  {
    name: 'costPerQuantity',
    headerName: 'Cost per Share',
    visible: false,
    order: 6,
    numberFormat: 'Currency.2',
  },
  {
    name: 'valuePerQuantity',
    headerName: 'Value per Share',
    visible: false,
    order: 7,
    numberFormat: 'Currency.2',
  },
  {
    name: 'initialInvestmentDate',
    headerName: 'Initial Investment Date',
    visible: false,
    order: 8,
    numberFormat: 'String',
  },
  {
    name: 'latestValuationDate',
    headerName: 'Latest Valuation Date',
    visible: false,
    order: 9,
    numberFormat: 'String',
  },
];

export const defaultRealizedColumnOrder: ValueFieldDef[] = [
  {
    name: 'shares',
    headerName: 'Shares',
    visible: true,
    order: 1,
    numberFormat: 'Decimal-use-comas.2',
  },
  {
    name: 'cost',
    headerName: 'Cost',
    visible: true,
    order: 2,
    numberFormat: 'Currency.0',
  },
  {
    name: 'realizedValue',
    headerName: 'Realized Value',
    visible: true,
    order: 3,
    numberFormat: 'Currency.0',
  },
  {
    name: 'realizedGainLoss',
    headerName: 'Realized Gain/Loss',
    visible: true,
    order: 4,
    numberFormat: 'Currency.0',
  },
  {
    name: 'moic',
    headerName: 'MOIC',
    visible: false,
    order: 5,
    numberFormat: 'String',
  },
  {
    name: 'costPerQuantity',
    headerName: 'Cost per Share',
    visible: false,
    order: 6,
    numberFormat: 'Currency.2',
  },
  {
    name: 'initialInvestmentDate',
    headerName: 'Initial Investment Date',
    visible: false,
    order: 7,
    numberFormat: 'String',
  },
];

const defaultSoiViews: ListItem[] = [
  { id: 'Unrealized_SOI', label: 'Unrealized SOI' },
  { id: 'Realized_SOI', label: 'Realized SOI' },
];

export function getDefaultCategoryFieldOrder(showFund: boolean) {
  const fundCat = defaultCategoryFieldOrder.find((c) => c.name === 'fund')!;

  fundCat.visible = showFund;

  return defaultCategoryFieldOrder;
}

export function getDefaultDataFieldOrder(soiView: string) {
  if (soiView === 'Unrealized_SOI') {
    return defaultUnrealizedColumnOrder;
  } else if (soiView === 'Realized_SOI') {
    return defaultRealizedColumnOrder;
  } else {
    throw 'Invalid SOI view';
  }
}

export function getDefaultSoiViews() {
  return defaultSoiViews;
}
