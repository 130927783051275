import { useTheme } from '@mui/material';

import {
  DashboardWidget,
  PieDataItem,
  WidgetType,
} from '../../../utils/types/visualDashboard.type';
import { getColor } from '../helpers/Chart.utils';
import { PageSummaryItem } from '../VisualDashboard';
import { VisualDashboardType } from '../VisualDashboard.constants';
import { isFundSummaryItemArray } from '../VisualDashboard.hooks';
import { PieChart } from './PieChart';

export function getPieChartData(
  summaryItems: PageSummaryItem[],
  pageType: string,
  dashboardWidgets: DashboardWidget[],
  currency: string
) {
  const theme = useTheme();

  let pageSummaryItems = [];
  let committedByFundDataItems: PieDataItem[] = [];
  let committedTotal = 0;
  let unfundedTotal = 0;
  let chartWidgets = [];

  switch (pageType) {
    case VisualDashboardType.CapitalAccounts:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);
      const accumulated = pageSummaryItems.reduce((acc: any, item) => {
        const { fundId, name, committed, unfunded } = item;
        const key = `${fundId}-${name}`;

        if (!acc[key]) {
          acc[key] = { id: fundId, value: 0, unfunded: 0, label: name };
        }
        acc[key].value += committed;
        acc[key].unfunded += unfunded;

        return acc;
      }, {});

      committedByFundDataItems = Object.values(accumulated).map(
        (item: any, index) => {
          committedTotal += item.value;
          unfundedTotal += item.unfunded;

          return {
            id: item.id,
            value: item.value,
            label: item.label,
            color: getColor(index),
          };
        }
      );
      break;
    case VisualDashboardType.Fund:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);

      committedByFundDataItems = pageSummaryItems.map<PieDataItem>(
        (fs, index) => {
          committedTotal += fs.committed;
          unfundedTotal += fs.unfunded;
          return {
            id: fs.id,
            value: fs.committed,
            label: fs.name,
            color: getColor(index),
          } as PieDataItem;
        }
      );
      break;
  }

  chartWidgets = dashboardWidgets
    ?.filter(
      (widget: DashboardWidget) =>
        widget.widgetType === WidgetType.Charts && widget.visible
    )
    .map((widget: DashboardWidget) => {
      let display = false;
      let chart: JSX.Element | undefined = undefined;

      switch (widget.name) {
        case 'committedByFund':
          chart = (
            <PieChart
              id="pieChart"
              pieDataItems={committedByFundDataItems}
              currency={currency}
              title="Commitments by Fund"
            />
          );
          if (committedByFundDataItems.length > 0) display = true;
          break;

        case 'committedToPaid':
          const committedToPaidDataItems: PieDataItem[] = [
            {
              id: 'funded',
              value: committedTotal - unfundedTotal,
              label: 'Funded',
              color: theme.palette.common.visUltramarineBlue,
            },
            {
              id: 'unfunded',
              value: unfundedTotal,
              label: 'Unfunded',
              color: theme.palette.common.visSkyBlue,
            },
          ];

          chart = (
            <PieChart
              id="committed_to_paid_pie_chart"
              pieDataItems={committedToPaidDataItems}
              currency={currency}
              title="Committed to Funded"
            />
          );
          if (committedByFundDataItems.length > 0) display = true;
          break;
      }

      return {
        index: widget.index,
        name: widget.name,
        title: widget.title,
        visible: widget.visible,
        widgetType: widget.widgetType,
        chart: chart,
        display: display,
      };
    });

  const filteredCharts = chartWidgets.filter(
    (chart) => chart.chart !== undefined
  );

  return { currency: currency, charts: filteredCharts };
}
