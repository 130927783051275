import { useContext, useEffect, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { AppContext } from '../../../../core/context/appContextProvider';
import {
  connectInvestmentTransactionToJournalEntry,
  getJournalEntriesByInvestmentTransaction,
  getJournalEntry,
} from '../../../../services/arkGL.service';
import { getPortfolioCompanyFilterList } from '../../../../services/filters.service';
import { useClientEffect } from '../../../../services/hooks/useClientsEffect/useClientEffect.hooks';
import {
  addConversionTransaction,
  addInvestmentTransaction,
  addMultipleValuationTransaction,
  addSaleTransaction,
  addStockSplitTransaction,
  addValuationTransaction,
  getAllValuationTransactions,
  getFollowingTransactionsList,
  getInvestmentTransaction,
  getInvestmentTransactionNameAvailability,
  getInvestmentTransactions,
  updateConversionTransaction,
  updateInvestmentTransaction,
  updateSaleTransaction,
  updateStockSplitTransaction,
  updateValuationTransaction,
} from '../../../../services/investmentTransaction.service';
import { FROM_VIEW } from '../../../../utils/constants/common.constants';
import { GENERIC_ERROR_MESSAGE } from '../../../../utils/constants/text.constants';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import { SelectedJournalEntry } from '../../../../utils/types/arkGLJournalEntry.type';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import {
  InvestmentTransaction,
  InvestmentTransactionFollowing,
  InvestmentTransactionNewView,
  InvestmentTransactionPurchase,
  SelectedInvestmentTransaction,
} from '../../../../utils/types/investmentTransaction.type';
import { FilterItem } from '../../../../utils/types/listItems';
import { SelectedPortfolioCompany } from '../../../../utils/types/portfolioCompany.type';
import {
  GET_JOURNAL_ENTRY_ERROR,
  GET_JOURNAL_ENTRY_LIST_ERROR,
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
} from '../../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import { GET_PORTFOLIO_COMPANY_LIST_ERROR } from '../../portfolioCompanies/PortfolioCompanyList.constants';
import {
  CONNECT_INVESTMENT_TRANSACTION_ERROR,
  CONNECT_INVESTMENT_TRANSACTION_SUCCESS,
  CREATE_INVESTMENT_TRANSACTION_ERROR,
  CREATE_INVESTMENT_TRANSACTION_SUCCESS,
  DISABLE_SEND_TO_GL_ALREADY_LINKED_TOOLTIP,
  DISABLE_SEND_TO_LOOP_TOOLTIP,
  ERROR_TAKEN_INVESTMENT_TRANSACTION_NAME,
  GET_INVESTMENT_TRANSACTION_DETAILS_ERROR,
  GET_INVESTMENT_TRANSACTION_LIST_ERROR,
  GET_TRANSACTION_LIST_ERROR,
  INVESTMENT_TRANSACTION_FOLLOWING_FORM_DEFAULT_VALUE,
  INVESTMENT_TRANSACTION_PURCHASE_FORM_DEFAULT_VALUE,
  NEW_INVESTMENT_TRANSACTION_TYPES,
  UPDATE_INVESTMENT_TRANSACTION_ERROR,
  UPDATE_INVESTMENT_TRANSACTION_SUCCESS,
} from '../InvestmentTransactionList.constants';

type Props = {
  investmentTransaction?: any;
  onClose: Function;
  fetchAllInvestmentTransactions: Function;
  isNewInvestmentTransaction: boolean;
  selectedInvestmentTransaction: SelectedInvestmentTransaction;
  setSelectedInvestmentTransaction: Function;
  viewPurchaseTransactionId?: string;
  fundId?: string;
  fromView?: string;
};

const initialPortfolioCompany: SelectedPortfolioCompany = {
  portfolioCompany: undefined,
  type: undefined,
};

const initialJournalEntry: SelectedJournalEntry = {
  journalEntry: undefined,
  type: undefined,
};

export const useInvestmentTransactionDetails = ({
  investmentTransaction,
  onClose,
  fetchAllInvestmentTransactions,
  isNewInvestmentTransaction,
  selectedInvestmentTransaction,
  setSelectedInvestmentTransaction,
  viewPurchaseTransactionId,
  fundId,
  fromView,
}: Props) => {
  const [loading, setLoading] = useState<LoadingStatus>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [showApplyMultipleConfirmation, setShowApplyMultipleConfirmation] =
    useState<boolean>(false);
  const [
    matchingPurchaseTransactionsText,
    setMatchingPurchaseTransactionsText,
  ] = useState<string>('');
  const [
    matchingPurchaseTransactionsList,
    setMatchingPurchaseTransactionsList,
  ] = useState<any[]>();
  const [followingTransactionList, setFollowingTransactionList] =
    useState<any[]>();
  const [transactionList, setTransactionList] = useState<any[]>();
  const [investmentTransactionType, setInvestmentTransactionType] =
    useState<string>('');
  const [initialPurchaseTransaction, setInitialPurchaseTransaction] =
    useState<any>();
  const [latestValuationAmount, setLatestValuationAmount] = useState<number>(0);
  const [latestValuationTransaction, setLatestValuationTransaction] =
    useState<any>();
  const [newValuationTransaction, setNewValuationTransaction] = useState<any>();
  const [transactionNameUnavailableError, setTransactionNameUnavailableError] =
    useState<boolean>(false);
  const [isNewButtonVisible, setIsNewButtonVisible] = useState<boolean>(false);
  const [
    newInvestmentTransactionTypeList,
    setNewInvestmentTransactionTypeList,
  ] = useState<FilterItem[]>([]);
  const [originalInvestmentTransaction, setOriginalInvestmentTransaction] =
    useState<any>();
  const [refetchPorfolioCompanyFilter, setRefetchPorfolioCompanyFilter] =
    useState<boolean>(false);
  const [selectedPortfolioCompany, setSelectedPortfolioCompany] =
    useState<SelectedPortfolioCompany>();
  const [selectedPortfolioCompanyFilter, setSelectedPortfolioCompanyFilter] =
    useState<string>();
  const [portfolioCompanyFilterList, setPortfolioCompanyFilterList] =
    useState<any>([]);
  const [purchaseTransactionList, setPurchaseTransactionList] = useState<any>(
    []
  );
  const [filteredTransactionList, setFilteredTransactionList] = useState<any>(
    []
  );
  const [selectedPurchaseTransactionId, setSelectedPurchaseTransactionId] =
    useState<string>();
  const [sendToGL, setSendToGL] = useState<boolean>(false);
  const [showSaleInfo, setShowSaleInfo] = useState<boolean>(false);
  const [saleInfo, setSaleInfo] = useState<{ data: any; e: any } | null>(null);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();
  const [journalEntryList, setJournalEntryList] = useState<any[]>();
  const [associatedJENoticeActionList, setAssociatedJENoticeActionList] =
    useState<any[]>();
  const [associatedJENoticeContent, setAssociatedJENoticeContent] =
    useState<string>();
  const [showAssociatedJENotice, setShowAssociatedJENotice] =
    useState<boolean>(false);
  const [showWriteOffNote, setShowWriteOffNote] = useState(false);
  const [createdTransactionId, setCreatedTransactionId] = useState<string>();
  const [isSendToGLDisabled, setIsSendToGLDisabled] = useState<boolean>(false);
  const [sendToGLTooltip, setSendToGLTooltip] = useState<string>();
  const [isFocused, setIsFocused] = useState<string>();

  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const { client } = useClientEffect(clientId !== 'new' ? clientId : undefined);
  const arkGlLocked = client?.arkGlLocked;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm<InvestmentTransactionPurchase>({
    defaultValues: investmentTransaction,
  });
  const { isDirty } = useFormState({
    control,
  });
  //  Following Investment Transaction
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    watch: watch2,
    reset: reset2,
    resetField: resetField2,
    setValue: setValue2,
    getValues,
  } = useForm<InvestmentTransactionFollowing>({
    defaultValues:
      investmentTransaction ??
      INVESTMENT_TRANSACTION_FOLLOWING_FORM_DEFAULT_VALUE,
  });
  const { isDirty: isDirty2 } = useFormState({
    control: control2,
  });

  const fetchPurchaseTransaction = async (
    id: string,
    isCanceled?: () => boolean
  ) => {
    setLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;
      const purchaseTransactionResponse = await getInvestmentTransaction(id);

      if (purchaseTransactionResponse) {
        setInitialPurchaseTransaction(purchaseTransactionResponse);

        if (isNewInvestmentTransaction) {
          await presetNewFormValues(
            investmentTransactionType,
            purchaseTransactionResponse
          );
        }
      }
    } catch (e) {
      informationAlert(GET_INVESTMENT_TRANSACTION_DETAILS_ERROR, 'error');
    } finally {
      setLoading(undefined);
    }
  };

  const fetchTransactionList = async (isCanceled?: () => boolean) => {
    setLoading(LoadingStatus.Loading);
    try {
      const transactionListResponse = await getInvestmentTransactions();

      setTransactionList(transactionListResponse.items);
    } catch (e) {
      informationAlert(GET_TRANSACTION_LIST_ERROR, 'error');
    } finally {
      setLoading(undefined);
    }
  };

  const fetchJournalEntryDetails = async (
    id: string,
    isCanceled?: () => boolean
  ) => {
    setLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;
      return await getJournalEntry(id);
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_ERROR, 'error');
    } finally {
      setLoading(undefined);
    }
  };

  const fetchJournalEntryList = async (isCanceled?: () => boolean) => {
    setLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;
      const response = await getJournalEntriesByInvestmentTransaction(
        investmentTransaction.id
      );

      if (response) {
        setJournalEntryList(response.items);

        if (response.items.length > 0) {
          setIsSendToGLDisabled(true);
          setSendToGLTooltip(DISABLE_SEND_TO_GL_ALREADY_LINKED_TOOLTIP);
        }
      }
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_LIST_ERROR, 'error');
    } finally {
      setLoading(undefined);
    }
  };

  const fetchFollowingTransactionList = async (
    fromTransactionId: string,
    isCanceled?: () => boolean
  ) => {
    setLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;
      const investmentTransactionList = await getFollowingTransactionsList(
        fromTransactionId!
      );

      // check if conversion does not exist, enable new button
      if (
        !investmentTransactionList.items.find(
          (item: any) => item.investmentTransactionType === 'CONVERSION'
        ) &&
        (investmentTransaction.investmentTransactionType === 'PURCHASE' ||
          investmentTransaction.investmentTransactionType === 'CONVERSION')
      ) {
        setIsNewButtonVisible(true);
      } else {
        setIsNewButtonVisible(false);
      }

      const updatedInvestmentTransactionList =
        investmentTransactionList.items.reduce(
          (
            filteredArray: any[],
            followingTransaction: InvestmentTransaction
          ) => {
            followingTransaction.fromTransactionId =
              fromTransactionId as string;

            if (
              investmentTransaction.investmentTransactionType === 'PURCHASE'
            ) {
              // display all if current is purchase or conversion
              followingTransaction.fromTransaction = investmentTransaction;
              filteredArray.push(followingTransaction);
            } else if (
              investmentTransaction.investmentTransactionType === 'CONVERSION'
            ) {
              // display all if current is purchase or conversion
              filteredArray.push(followingTransaction);
            }

            return filteredArray;
          },
          []
        );

      const sorted = updatedInvestmentTransactionList
        .sort((a: any, b: any) => b.date - a.date)
        .reverse();

      setFollowingTransactionList(sorted);

      if (investmentTransaction.investmentTransactionType === 'SPLIT') {
        const split = investmentTransactionList.items.find(
          (t: any) => t.id === investmentTransaction.id
        );

        if (split) {
          investmentTransaction.previousCostPerQuantity =
            split.previousCostPerQuantity;
          investmentTransaction.previousQuantity = split.previousQuantity;
        }
      }
    } catch (e) {
      informationAlert(GET_INVESTMENT_TRANSACTION_LIST_ERROR, 'error');
    }
    setLoading(undefined);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchTransactionList(isCanceled);
    if (
      investmentTransaction &&
      investmentTransaction.id &&
      !isNewInvestmentTransaction
    ) {
      await fetchJournalEntryList(isCanceled);
      setInvestmentTransactionType(
        investmentTransaction.investmentTransactionType
      );
    }
  }, []);

  useEffect(() => {
    if (selectedPortfolioCompanyFilter) {
      const filteredTransactionList = purchaseTransactionList.filter(
        (t: any) => t.portfolioCompanyId === selectedPortfolioCompanyFilter
      );

      setFilteredTransactionList(filteredTransactionList);
    }
  }, [selectedPortfolioCompanyFilter]);

  useEffect(() => {
    if (isNewInvestmentTransaction) {
      if (
        selectedInvestmentTransaction.newView ===
        InvestmentTransactionNewView.Purchase
      ) {
        setNewInvestmentTransactionTypeList(NEW_INVESTMENT_TRANSACTION_TYPES);
      } else {
        const currentList = NEW_INVESTMENT_TRANSACTION_TYPES.filter(
          (type) => type.id !== 'PURCHASE'
        );

        setNewInvestmentTransactionTypeList(currentList);
      }
    }

    if (
      !!selectedInvestmentTransaction?.connectTransactionToJournalEntry
        ?.journalEntryId
    ) {
      setIsSendToGLDisabled(true);
      setSendToGLTooltip(DISABLE_SEND_TO_LOOP_TOOLTIP);
    }
  }, [selectedInvestmentTransaction]);

  useEffectAsync(
    async (isCanceled) => {
      if (
        isNewInvestmentTransaction &&
        investmentTransactionType &&
        investmentTransactionType !== 'PURCHASE'
      ) {

        try {
          const portCoResponse = await getPortfolioCompanyFilterList();

          setPortfolioCompanyFilterList(
            portCoResponse.sort((a: any, b: any) =>
              a.name < b.name ? -1 : 1
            )
          );

        } catch (e) {
          informationAlert(GET_PORTFOLIO_COMPANY_LIST_ERROR, 'error');
        }

        try {
          const purchaseTransactionResponse = await getInvestmentTransactions();

          const transactions = purchaseTransactionResponse.items.filter(
            (transaction: any) =>
              ['PURCHASE', 'CONVERSION'].includes(
                transaction.investmentTransactionType
              )
          );

          const latestTransactionIds: any[] = [];

          transactions.forEach((transaction: any) => {
            const f: any = (current: any) => {
              const followingTransaction = transactions.find(
                (t: any) => t.fromTransaction && t.fromTransaction.id === current.id
              );

              if (followingTransaction === undefined) {
                latestTransactionIds.push(current.id);
                return;
              }
              return f(followingTransaction.id);
            };

            f(transaction);
          });

          const latestTransactions = latestTransactionIds
            .filter((v) => v !== undefined)
            .map((transactionId: any) => {
              const t = transactions.find((t: any) => t.id === transactionId);

              return {
                id: t.id,
                name: `${DateTimeFormat.shortDateString(t.date)}, ${t.name}, ${t.portfolioCompany.name
                  }, ${t.securityType}, ${t.fund.name}`,
                portfolioCompanyId: t.portfolioCompany.id
              };
            })
            .sort();

          setPurchaseTransactionList(latestTransactions);
        } catch (e) {
          informationAlert(GET_TRANSACTION_LIST_ERROR, 'error');
        }
      }
    },
    [investmentTransactionType]
  );

  useEffectAsync(
    async (isCanceled) => {
      if (
        investmentTransaction &&
        investmentTransaction.id &&
        !isNewInvestmentTransaction
      ) {
        setInvestmentTransactionType(
          investmentTransaction.investmentTransactionType
        );

        let purchaseId: string;
        let fromTransactionId: string;

        if (investmentTransaction.investmentTransactionType === 'PURCHASE') {
          purchaseId = investmentTransaction.id;
        } else {
          purchaseId = investmentTransaction.fromTransactionId;
          if (
            investmentTransaction.fromTransaction &&
            investmentTransaction.fromTransaction.id
          ) {
            setInitialPurchaseTransaction(
              investmentTransaction.fromTransaction
            );
          } else {
            await fetchPurchaseTransaction(purchaseId, isCanceled);
          }
        }

        if (investmentTransaction.investmentTransactionType === 'PURCHASE') {
          fromTransactionId = purchaseId;
        } else if (
          investmentTransaction.investmentTransactionType === 'CONVERSION'
        ) {
          fromTransactionId = investmentTransaction.id;
        } else {
          fromTransactionId = investmentTransaction.fromTransactionId
            ? investmentTransaction.fromTransactionId
            : investmentTransaction.fromTransaction.id;
        }

        await fetchFollowingTransactionList(fromTransactionId!, isCanceled);

        presetUpdateFormValues();
      }

      if (isNewInvestmentTransaction && viewPurchaseTransactionId) {
        setSelectedPurchaseTransactionId(viewPurchaseTransactionId);
      }
    },
    [investmentTransaction]
  );

  useEffectAsync(
    async (isCanceled) => {
      if (isNewInvestmentTransaction && selectedPurchaseTransactionId) {
        await fetchPurchaseTransaction(
          selectedPurchaseTransactionId,
          isCanceled
        );
      }
    },
    [selectedPurchaseTransactionId]
  );

  useEffect(() => {
    reset(investmentTransaction);
    setTransactionNameUnavailableError(false);
  }, [investmentTransaction, reset]);

  const presetNewFormValues = async (
    transactionType: string,
    fromTransaction: any
  ) => {
    switch (transactionType) {
      case 'SALE_FULL': {
        setValue2('quantityOther', fromTransaction.quantity);
        setValue2('amountPerQuantityOther', '');
        if (
          selectedInvestmentTransaction.newView ===
          InvestmentTransactionNewView.All
        ) {
          setValue2('totalAmountOther', investmentTransaction.totalAmount);
        } else {
          setValue2('totalAmountOther', '');
        }
        break;
      }
      case 'SALE_PARTIAL': {
        setValue2('quantityOther', '');
        setValue2('amountPerQuantityOther', '');
        if (
          selectedInvestmentTransaction.newView ===
          InvestmentTransactionNewView.All
        ) {
          setValue2('totalAmountOther', investmentTransaction.totalAmount);
        } else {
          setValue2('totalAmountOther', '');
        }
        break;
      }
      case 'NET_EFFECT_VALUATION': {
        if (
          selectedInvestmentTransaction.newView ===
          InvestmentTransactionNewView.All
        ) {
          setValue2('totalAmountOther', investmentTransaction.totalAmount);
        } else {
          setValue2('totalAmountOther', '');
        }
        setValue2('amountPerQuantityOther', '');

        const latestTransaction = findLatestTransactions([fromTransaction]);

        setValue2('quantityOther', latestTransaction[0].quantity);
        setValue2('newTotalAmount', null);
        setValue2('newAmountPerQuantity', null);

        if (selectedPurchaseTransactionId) {
          const valuationsResponse = await getAllValuationTransactions(
            selectedPurchaseTransactionId
          );

          if (valuationsResponse && valuationsResponse.items.length !== 0) {
            const latestValuation = valuationsResponse.items[0];

            setLatestValuationAmount(latestValuation.totalAmount);
          } else {
            setLatestValuationAmount(fromTransaction.totalAmount);
          }
        }
        break;
      }
      case 'TOTAL_VALUATION': {
        if (
          selectedInvestmentTransaction.newView ===
          InvestmentTransactionNewView.All
        ) {
          setValue2('totalAmountOther', investmentTransaction.totalAmount);
        } else {
          setValue2('totalAmountOther', '');
        }
        setValue2('amountPerQuantityOther', '');

        const latestTransaction = findLatestTransactions([fromTransaction]);

        setLatestValuationTransaction(latestTransaction[0]);

        setValue2('quantityOther', latestTransaction[0].quantity);
        break;
      }
      case 'WRITE_OFF': {
        setValue2('totalAmountOther', 0);
        setValue2('quantityOther', fromTransaction.quantity);
        break;
      }
      case 'SPLIT': {
        setValue2('quantityOther', '');
        setValue2('totalAmountOther', fromTransaction.totalAmount);
        break;
      }
      case 'CONVERSION': {
        setValue2('quantityOther', '');
        setValue2('amountPerQuantityOther', '');
        if (
          selectedInvestmentTransaction.newView ===
          InvestmentTransactionNewView.All
        ) {
          setValue2('totalAmountOther', investmentTransaction.totalAmount);
        } else {
          setValue2('totalAmountOther', '');
        }
        break;
      }
    }
  };

  const presetUpdateFormValues = () => {
    setValue2('quantityOther', investmentTransaction.quantity);
    if (investmentTransaction.amountPerQuantity) {
      setValue2(
        'amountPerQuantityOther',
        investmentTransaction.amountPerQuantity
      );
    }
    setValue2('totalAmountOther', investmentTransaction.totalAmount);
    setValue2('date', investmentTransaction.date);
    switch (investmentTransaction.investmentTransactionType) {
      case 'SPLIT': {
        setValue2(
          'amountPerQuantityOther',
          investmentTransaction.previousCostPerQuantity /
          (investmentTransaction.quantity /
            investmentTransaction.previousQuantity)
        );
        setValue2(
          'totalAmountOther',
          investmentTransaction.previousCostPerQuantity *
          investmentTransaction.previousQuantity
        );
        break;
      }
      case 'SALE': {
        setValue2(
          'removeFromHistoricalSoi',
          investmentTransaction.removeFromHistoricalSoi
            ? investmentTransaction.removeFromHistoricalSoi
            : false
        );
        break;
      }
      case 'CONVERSION': {
        setValue2(
          'newSecurityType',
          investmentTransaction.securityType
            ? investmentTransaction.securityType
            : ''
        );
        setValue2(
          'newInvestmentSecurity',
          investmentTransaction.investmentSecurity
            ? investmentTransaction.investmentSecurity
            : ''
        );
        setValue2(
          'newCommitmentAmount',
          investmentTransaction.commitmentAmount
            ? investmentTransaction.commitmentAmount
            : ''
        );
        setValue2(
          'newNoteAgreement',
          investmentTransaction.noteAgreement
            ? investmentTransaction.noteAgreement
            : ''
        );
        setValue2(
          'newInterestRate',
          investmentTransaction.interestRate
            ? investmentTransaction.interestRate
            : ''
        );
        break;
      }
      // case "VALUATION": {
      // if (investmentTransaction.entryType === 'NET_EFFECT_VALUATION') {
      //   const valuationsResponse = await getAllValuationTransactions(investmentTransaction.id);

      //   if (valuationsResponse && valuationsResponse.items.length > 0) {
      //     const currentValuation = valuationsResponse.items[0];

      //     setLatestValuationAmount(latestValuation.totalAmount);
      //   } else {
      //     setLatestValuationAmount(initialPurchaseTransaction.totalAmount);
      //   }

      // setValue2("amountPerQuantityOther", investmentTransaction.previousCostPerQuantity);
      // setValue2("totalAmountOther", investmentTransaction.previousCostPerQuantity * investmentTransaction.previousQuantity);
      // break;
      // }
    }
  };

  const checkMatchingPortcoSecurityTransactions = async (
    purchaseTransaction: any
  ) => {
    setLoading(LoadingStatus.Loading);
    try {
      const transactionListResponse = await getInvestmentTransactions();

      setTransactionList(transactionListResponse.items);

      const matchingConversionTransactions: any[] = [];
      const matchingPurchaseTransactions: any[] = [];

      transactionListResponse.items.forEach((t: any) => {
        if (
          t.portfolioCompany.id === purchaseTransaction.portfolioCompany.id &&
          t.securityType === purchaseTransaction.securityType &&
          t.id !== purchaseTransaction.id
        ) {
          if (t.investmentTransactionType === 'CONVERSION') {
            matchingConversionTransactions.push(t);
          }
          if (t.investmentTransactionType === 'PURCHASE') {
            matchingPurchaseTransactions.push(t);
          }
        }
      });

      const matchingLatestTransactions = findLatestTransactions(
        matchingPurchaseTransactions,
        transactionListResponse.items
      );

      return [...matchingConversionTransactions, ...matchingLatestTransactions];
    } catch (e) {
      informationAlert(GET_TRANSACTION_LIST_ERROR, 'error');
    }
    setLoading(undefined);
  };

  const findLatestTransactions = (
    matchingPurchaseTransactions: any[],
    transactions = transactionList
  ) => {
    const list: any[] = [];

    if (transactions && transactions.length) {
      matchingPurchaseTransactions.forEach((p) => {
        const latest = transactions.find(
          (t: any) =>
            (t.investmentTransactionType === 'SPLIT' ||
              t.investmentTransactionType === 'VALUATION') &&
            t.fromTransaction &&
            t.fromTransaction.id === p.id
        );

        if (latest) {
          list.push(latest);
        } else {
          list.push(p);
        }
      });
    }

    return list;
  };

  const createPurchaseTransaction = async (
    data: InvestmentTransaction,
    e: any
  ) => {
    try {
      const createdTransaction = await addInvestmentTransaction(data);

      informationAlert(CREATE_INVESTMENT_TRANSACTION_SUCCESS, 'success');
      fetchAllInvestmentTransactions();

      if (
        selectedInvestmentTransaction.connectTransactionToJournalEntry &&
        selectedInvestmentTransaction.connectTransactionToJournalEntry
          .journalEntryId
      ) {
        await connectTransactionToJournalEntry({
          journalEntryId:
            selectedInvestmentTransaction.connectTransactionToJournalEntry
              .journalEntryId,
          portfolioInvestmentId: createdTransaction.id,
        });
      }

      onSave(data, e, createdTransaction.id);
    } catch (error) {
      informationAlert(CREATE_INVESTMENT_TRANSACTION_ERROR, 'error');
    }
  };

  const editPurchaseTransaction = async (
    data: InvestmentTransaction,
    e: any
  ) => {
    if (data.id) {
      try {
        await updateInvestmentTransaction(data, data.id);

        informationAlert(UPDATE_INVESTMENT_TRANSACTION_SUCCESS, 'success');

        onSave(data, e, data.id);
      } catch (error) {
        informationAlert(UPDATE_INVESTMENT_TRANSACTION_ERROR, 'error');
      }
    }
  };

  const onSubmitPurchaseTransaction = async (
    data: InvestmentTransaction,
    e?: any
  ) => {
    if (data.name) {
      data.name = data.name.trim();

      if (
        isNewInvestmentTransaction ||
        investmentTransaction.name !== data.name
      ) {
        const investmentTransactionNameIsAvailable =
          await validateTransactionName(data.name);

        if (!investmentTransactionNameIsAvailable) {
          setTransactionNameUnavailableError(true);
          return;
        }
      }
    }

    let resultData = {
      ...data,
      tenantId: clientId,
      portfolioCompanyId: data.portfolioCompany?.id,
      fundId: data.fund?.id,
      costPerQuantity: data.quantity !== '0' ? data.amountPerQuantity : 0,
      totalCost: data.totalAmount,
    };

    if (isNewInvestmentTransaction) {
      resultData = {
        ...(isNewInvestmentTransaction
          ? INVESTMENT_TRANSACTION_PURCHASE_FORM_DEFAULT_VALUE
          : {}),
        ...resultData,
      };

      setLoading(LoadingStatus.Adding);
      await createPurchaseTransaction(resultData, e);
    } else {
      setLoading(LoadingStatus.Updating);
      await editPurchaseTransaction(resultData, e);
    }

    setLoading(undefined);
  };

  const createFollowingTransaction = async (
    transactionType: string,
    data: any,
    e: any
  ) => {
    let createdTransaction;
    let matchingPurchaseTransactions;

    try {
      switch (transactionType) {
        case 'SALE_PARTIAL':
        case 'SALE_FULL':
        case 'WRITE_OFF':
          createdTransaction = await addSaleTransaction(
            data,
            data.investmentTransaction.id
          );
          break;
        case 'TOTAL_VALUATION':
        case 'NET_EFFECT_VALUATION': {
          matchingPurchaseTransactions =
            await checkMatchingPortcoSecurityTransactions(
              initialPurchaseTransaction
            );

          if (
            matchingPurchaseTransactions &&
            matchingPurchaseTransactions.length > 0
          ) {
            let text = '';

            matchingPurchaseTransactions.map((purchase: any) => {
              text += `${purchase.name} - ${purchase.fund?.name} - ${purchase.portfolioCompany?.name
                } - ${purchase.securityType} ${'\n'} `;
            });

            setMatchingPurchaseTransactionsList(matchingPurchaseTransactions);
            setMatchingPurchaseTransactionsText(text);

            if (transactionType === 'NET_EFFECT_VALUATION') {
              setNewValuationTransaction({
                ...data,
                netAmount: data.netAmount,
                netAmountPerQuantity: data.netAmountPerQuantity,
              });
            } else {
              setNewValuationTransaction(data);
            }
            setShowApplyMultipleConfirmation(true);
          } else {
            createdTransaction = await addValuationTransaction(
              data,
              data.investmentTransaction.id
            );
          }

          break;
        }
        case 'SPLIT':
          createdTransaction = await addStockSplitTransaction(
            data,
            data.investmentTransaction.id
          );
          break;
        case 'CONVERSION':
          createdTransaction = await addConversionTransaction(
            data,
            data.investmentTransaction.id
          );
          break;
      }
      if (
        !(
          (transactionType === 'TOTAL_VALUATION' ||
            transactionType === 'NET_EFFECT_VALUATION') &&
          matchingPurchaseTransactions &&
          matchingPurchaseTransactions.length > 0
        )
      ) {
        informationAlert(CREATE_INVESTMENT_TRANSACTION_SUCCESS, 'success');
      }
      if (
        selectedInvestmentTransaction.connectTransactionToJournalEntry &&
        selectedInvestmentTransaction.connectTransactionToJournalEntry
          .journalEntryId
      ) {
        await connectTransactionToJournalEntry({
          journalEntryId:
            selectedInvestmentTransaction.connectTransactionToJournalEntry
              .journalEntryId,
          portfolioInvestmentId: createdTransaction.id,
        });
      }

      if (transactionType === 'NET_EFFECT_VALUATION') {
        data.totalAmount = data.netAmount;
      }

      onSave(data, e, createdTransaction.id);
    } catch (error) {
      if (
        !(
          (transactionType === 'TOTAL_VALUATION' ||
            transactionType === 'NET_EFFECT_VALUATION') &&
          matchingPurchaseTransactions &&
          matchingPurchaseTransactions.length > 0
        )
      ) {
        informationAlert(CREATE_INVESTMENT_TRANSACTION_ERROR, 'error');
      }
    }
  };

  const editFollowingTransaction = async (
    transactionType: string,
    data: any,
    e: any
  ) => {
    if (investmentTransaction.id) {
      const fromTransactionId = investmentTransaction.fromTransactionId
        ? investmentTransaction.fromTransactionId
        : investmentTransaction.fromTransaction.id;

      try {
        switch (transactionType) {
          case 'SALE':
          case 'WRITE_OFF':
            await updateSaleTransaction(
              data,
              fromTransactionId,
              investmentTransaction.id
            );
            break;
          case 'VALUATION':
            await updateValuationTransaction(
              data,
              fromTransactionId,
              investmentTransaction.id
            );
            break;
          case 'SPLIT':
            await updateStockSplitTransaction(
              data,
              fromTransactionId,
              investmentTransaction.id
            );
            break;
          case 'CONVERSION':
            await updateConversionTransaction(data, fromTransactionId);
            break;
        }
        informationAlert(UPDATE_INVESTMENT_TRANSACTION_SUCCESS, 'success');
        onSave(data, e, data.id);
      } catch (error) {
        informationAlert(UPDATE_INVESTMENT_TRANSACTION_ERROR, 'error');
      }
    }
  };

  const onSubmitFollowingTransaction = async (
    data: InvestmentTransactionFollowing,
    e?: any
  ) => {
    let resultData = {};

    switch (investmentTransactionType) {
      case 'SALE_PARTIAL':
        resultData = {
          removeFromHistoricalSoi: data.removeFromHistoricalSoi
            ? data.removeFromHistoricalSoi
            : false,
          totalAmount: data.totalAmountOther,
          saleType: 'PARTIAL',
          quantity: data.quantityOther,
        };
        break;
      case 'SALE_FULL':
        resultData = {
          removeFromHistoricalSoi: data.removeFromHistoricalSoi
            ? data.removeFromHistoricalSoi
            : false,
          totalAmount: data.totalAmountOther,
          saleType: 'FULL',
          quantity: data.quantityOther,
        };
        break;
      case 'WRITE_OFF': {
        resultData = {
          removeFromHistoricalSoi: data.removeFromHistoricalSoi
            ? data.removeFromHistoricalSoi
            : false,
          totalAmount: data.totalAmountOther,
          saleType: 'WRITE_OFF',
          quantity: data.quantityOther,
        };
        break;
      }
      case 'SALE': {
        resultData = {
          removeFromHistoricalSoi: data.removeFromHistoricalSoi
            ? data.removeFromHistoricalSoi
            : false,
          totalAmount: data.totalAmountOther,
          saleType: investmentTransaction.saleType
            ? investmentTransaction.saleType
            : investmentTransaction.subtype,
          quantity: data.quantityOther,
        };
        break;
      }
      case 'TOTAL_VALUATION':
        resultData = {
          investmentTransactionType: 'VALUATION',
          amountPerQuantity: data.amountPerQuantityOther,
          totalAmount: data.totalAmountOther,
          entryType: 'TOTAL_VALUATION',
          quantity: data.quantityOther,
        };
        break;
      case 'NET_EFFECT_VALUATION':
        resultData = {
          investmentTransactionType: 'VALUATION',
          amountPerQuantity: data.newAmountPerQuantity,
          totalAmount: data.newTotalAmount,
          entryType: 'NET_EFFECT_VALUATION',
          quantity: data.quantityOther,
          netAmount: data.totalAmountOther,
          netAmountPerQuantity: data.amountPerQuantityOther,
        };
        break;
      case 'VALUATION':
        // if (investmentTransaction.entryType === 'NET_EFFECT_VALUATION') {
        //   data.amountPerQuantityOther = (latestValuationAmount + data.totalAmountOther) / initialPurchaseTransaction.quantity;
        //   data.totalAmountOther = (latestValuationAmount + data.totalAmountOther);
        // }
        resultData = {
          investmentTransactionType: 'VALUATION',
          amountPerQuantity: data.amountPerQuantityOther,
          totalAmount: data.totalAmountOther,
          entryType: investmentTransaction.entryType
            ? investmentTransaction.entryType
            : investmentTransaction.subtype,
          quantity: data.quantityOther,
        };
        break;
      case 'SPLIT': {
        resultData = {
          quantity: data.quantityOther,
        };
        break;
      }
      case 'CONVERSION': {
        resultData = {
          quantity: data.quantityOther,
          totalConvertedCost: data.totalAmountOther,
          costPerQuantity:
            data.quantityOther !== '0' ? data.amountPerQuantityOther : 0,
          securityType: data.newSecurityType,
          interestRate: data.newInterestRate,
          commitmentAmount: data.newCommitmentAmount,
          noteAgreement: data.newNoteAgreement,
          investmentSecurity: data.newInvestmentSecurity,
        };
        break;
      }
    }

    if (isNewInvestmentTransaction) {
      resultData = {
        ...resultData,
        tenantId: clientId,
        date: DateTimeFormat.noMilliseconds(data.date as Date),
        investmentTransaction: {
          id: selectedPurchaseTransactionId,
        },
      };
      setLoading(LoadingStatus.Adding);
      await createFollowingTransaction(
        investmentTransactionType,
        resultData,
        e
      );
      fetchAllInvestmentTransactions();
    } else {
      resultData = {
        ...resultData,
        tenantId: clientId,
        date: DateTimeFormat.noMilliseconds(
          typeof data.date === 'object'
            ? (data.date as Date)
            : new Date(data.date as string)
        ),
        investmentTransaction: {
          id: investmentTransaction.fromTransactionId
            ? investmentTransaction.fromTransactionId
            : investmentTransaction.fromTransaction.id,
        },
        id: investmentTransaction.id,
      };
      setLoading(LoadingStatus.Updating);

      await editFollowingTransaction(investmentTransactionType, resultData, e);
    }

    setLoading(undefined);
  };

  const connectTransactionToJournalEntry = async (data: any) => {
    try {
      setLoading(LoadingStatus.Connecting);
      await connectInvestmentTransactionToJournalEntry(data);
      informationAlert(CONNECT_INVESTMENT_TRANSACTION_SUCCESS, 'success');
    } catch (e) {
      informationAlert(CONNECT_INVESTMENT_TRANSACTION_ERROR, 'error');
    } finally {
      setLoading(undefined);
    }
  };

  const validateTransactionName: any = async (value: string) => {
    if (value) {
      try {
        return await getInvestmentTransactionNameAvailability(value);
      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, 'error');
      }
    }
  };

  const handleInvestmentTransactionNameValidation = async (value: string) => {
    if (value) {
      value = value.trim();

      if (
        isNewInvestmentTransaction ||
        (investmentTransaction && value !== investmentTransaction.name)
      ) {
        const investmentTransactionNameIsAvailable =
          await validateTransactionName(value);

        investmentTransactionNameIsAvailable
          ? setTransactionNameUnavailableError(false)
          : setTransactionNameUnavailableError(true);
      } else {
        setTransactionNameUnavailableError(false);
      }
    }
  };

  const sendToJournalEntryDetails = (data: any, transactionIds: string[]) => {
    setSelectedJournalEntry({
      journalEntry: {
        ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
        fundId:
          data.fund && data.fund.id
            ? data.fund.id
            : investmentTransaction &&
              investmentTransaction.fromTransaction &&
              investmentTransaction.fromTransaction.fund.id
              ? investmentTransaction.fromTransaction.fund.id
              : initialPurchaseTransaction &&
                initialPurchaseTransaction.fund &&
                initialPurchaseTransaction.fund.id
                ? initialPurchaseTransaction.fund.id
                : null,
        lineItems: [
          {
            ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
            entities:
              data.portfolioCompany && data.portfolioCompany.id
                ? [
                  {
                    id: data.portfolioCompany.id,
                    name: data.portfolioCompany.name,
                  },
                ]
                : initialPurchaseTransaction &&
                  initialPurchaseTransaction.portfolioCompany &&
                  initialPurchaseTransaction.portfolioCompany.id
                  ? [
                    {
                      id: initialPurchaseTransaction.portfolioCompany.id,
                      name: initialPurchaseTransaction.portfolioCompany.name,
                    },
                  ]
                  : [],
            entryType: 'DEBIT',
            amount: data.totalAmount
              ? parseFloat(data.totalAmount)
              : data.totalAmountOther
                ? parseFloat(data.totalAmountOther)
                : parseFloat(data.totalConvertedCost),
          },
          {
            ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
            entities:
              data.portfolioCompany && data.portfolioCompany.id
                ? [
                  {
                    id: data.portfolioCompany.id,
                    name: data.portfolioCompany.name,
                  },
                ]
                : initialPurchaseTransaction &&
                  initialPurchaseTransaction.portfolioCompany &&
                  initialPurchaseTransaction.portfolioCompany.id
                  ? [
                    {
                      id: initialPurchaseTransaction.portfolioCompany.id,
                      name: initialPurchaseTransaction.portfolioCompany.name,
                    },
                  ]
                  : [],
            entryType: 'CREDIT',
            amount: data.totalAmount
              ? parseFloat(data.totalAmount)
              : data.totalAmountOther
                ? parseFloat(data.totalAmountOther)
                : parseFloat(data.totalConvertedCost),
          },
        ],
        date: data.date,
      },
      type: DetailsType.New,
      connectTransactionToJournalEntry: {
        investmentTransactionIds: transactionIds,
      },
    });
  };

  const onSave = async (data: any, e: any, transactionId: string) => {
    switch (e.nativeEvent.submitter.id) {
      case 'btn_investment_transaction_save_and_sendtogl':
        if (
          data.entryType === 'TOTAL_VALUATION' &&
          latestValuationTransaction &&
          latestValuationTransaction.investmentTransactionType === 'VALUATION'
        ) {
          data.totalAmount = Math.abs(
            parseFloat(data.totalAmount) -
            latestValuationTransaction.totalAmount
          );
        }

        if (data.saleType && data.saleType === 'WRITE_OFF') {
          setCreatedTransactionId(transactionId);
          setShowWriteOffNote(true);
          return;
        }

        sendToJournalEntryDetails(data, [transactionId]);
        fetchAllInvestmentTransactions();
        break;
      case 'investment_transaction_save_button':
        let newTotalAmount = 0;

        switch (data.investmentTransactionType) {
          case 'PURCHASE':
            newTotalAmount = Math.abs(parseFloat(data.totalCost));
            break;
          default:
            newTotalAmount = Math.abs(parseFloat(data.totalAmount));
            break;
        }

        if (
          fromView !== FROM_VIEW.JOURNAL_ENTRIES &&
          !arkGlLocked &&
          journalEntryList &&
          journalEntryList.length > 0 &&
          (newTotalAmount !== investmentTransaction.totalAmount ||
            !!DateTimeFormat.compareDateOnly(
              data.date,
              investmentTransaction.date
            ) ||
            data.fundId !== investmentTransaction.fund.id ||
            data.portfolioCompanyId !==
            investmentTransaction.portfolioCompany.id)
        ) {
          const existingJournalEntry = await fetchJournalEntryDetails(
            journalEntryList[0].journalEntryId
          );

          let newJEAmount = 0;

          if (existingJournalEntry) {
            switch (existingJournalEntry.state) {
              case 'DRAFT':
                setAssociatedJENoticeActionList([
                  {
                    label: 'Review Proposed Journal Entry Adjustments',
                    onClick: () => {
                      switch (data.investmentTransactionType) {
                        case 'PURCHASE':
                          newJEAmount = Math.abs(parseFloat(data.totalCost));
                          break;
                        default:
                          newJEAmount = Math.abs(parseFloat(data.totalAmount));
                          break;
                      }

                      if (existingJournalEntry.lineItems.length === 2) {
                        newJEAmount =
                          newJEAmount *
                          Math.pow(10, existingJournalEntry.currencyDecimal!);

                        const newPortfolioCompany =
                          data.portfolioCompany && data.portfolioCompany.id
                            ? {
                              id: data.portfolioCompany.id,
                              name: data.portfolioCompany.name,
                            }
                            : initialPurchaseTransaction &&
                              initialPurchaseTransaction.portfolioCompany &&
                              initialPurchaseTransaction.portfolioCompany.id
                              ? {
                                id: initialPurchaseTransaction.portfolioCompany
                                  .id,
                                name: initialPurchaseTransaction
                                  .portfolioCompany.name,
                              }
                              : null;

                        const newLineItems = existingJournalEntry.lineItems.map(
                          (l: any) => {
                            let entities: any[] = [];

                            if (l.entities.length) {
                              entities = [...l.entities];
                              if (
                                newPortfolioCompany?.id !==
                                investmentTransaction.portfolioCompany.id
                              ) {
                                const currentPortfolioCompanyIndex =
                                  l.entities.findIndex(
                                    (p: any) =>
                                      p.id ===
                                      investmentTransaction.portfolioCompany.id
                                  );

                                if (currentPortfolioCompanyIndex >= 0) {
                                  entities[currentPortfolioCompanyIndex] =
                                    newPortfolioCompany;
                                } else {
                                  entities.push(newPortfolioCompany);
                                }
                              }
                            } else {
                              entities = [newPortfolioCompany];
                            }

                            return {
                              ...l,
                              entities: entities,
                              amount: newJEAmount,
                            };
                          }
                        );

                        handleEditJournalEntryDetails({
                          ...existingJournalEntry,
                          fundId:
                            data.fund && data.fund.id
                              ? data.fund.id
                              : investmentTransaction &&
                                investmentTransaction.fromTransaction &&
                                investmentTransaction.fromTransaction.fund.id
                                ? investmentTransaction.fromTransaction.fund.id
                                : initialPurchaseTransaction &&
                                  initialPurchaseTransaction.fund &&
                                  initialPurchaseTransaction.fund.id
                                  ? initialPurchaseTransaction.fund.id
                                  : null,
                          lineItems: newLineItems,
                          date: data.date,
                        });
                      }

                      setShowAssociatedJENotice(false);
                    },
                    id: 'btn_review_je',
                    variant: 'contained',
                    color: 'primary',
                  },
                ]);
                setAssociatedJENoticeContent(
                  'A change has been made to an Investment Transaction with an associated Journal Entry. Adjustments have been suggested for the associated Journal Entry. Please be aware that the proposed changes will only be saved upon review and confirmation.'
                );
                setShowAssociatedJENotice(true);
                break;
              case 'POSTED':
                setAssociatedJENoticeActionList([
                  {
                    label: 'View Posted Journal Entry',
                    onClick: () => {
                      handleViewJournalEntryDetails(existingJournalEntry.id);
                      setShowAssociatedJENotice(false);
                    },
                    id: 'btn_view_posted_je',
                    variant: 'contained',
                    color: 'primary',
                  },
                  {
                    label: 'Create New Journal Entry',
                    onClick: () => {
                      newJEAmount = Math.abs(
                        parseFloat(data.totalAmount) -
                        investmentTransaction.totalAmount
                      );

                      data.totalAmount = newJEAmount;
                      sendToJournalEntryDetails(data, [transactionId]);
                      setShowAssociatedJENotice(false);
                    },
                    id: 'btn_create_new_je',
                    variant: 'contained',
                    color: 'primary',
                  },
                ]);
                setAssociatedJENoticeContent(
                  'A change has been made to an Investment Transaction with an associated, posted Journal Entry.'
                );
                setShowAssociatedJENotice(true);
            }
          }

          fetchAllInvestmentTransactions();
          return;
        }
        fetchAllInvestmentTransactions();
        closeDrawer();
        break;
    }
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleGoBack = () => {
    initialPurchaseTransaction.fromTransaction &&
      originalInvestmentTransaction &&
      originalInvestmentTransaction.investmentTransaction
      ? setSelectedInvestmentTransaction({
        investmentTransactionType: 'PURCHASE',
        investmentTransaction:
          originalInvestmentTransaction.investmentTransaction,
        viewPurchaseTransactionId:
          originalInvestmentTransaction.investmentTransaction,
        type: DetailsType.Edit,
      })
      : setSelectedInvestmentTransaction({
        investmentTransactionType: 'PURCHASE',
        investmentTransaction: initialPurchaseTransaction,
        viewPurchaseTransactionId: initialPurchaseTransaction.id,
        type: DetailsType.Edit,
      });
  };

  const handleChangeTransactionType = async (transactionType: any) => {
    setInvestmentTransactionType(transactionType);
    if (initialPurchaseTransaction) {
      await presetNewFormValues(transactionType, initialPurchaseTransaction);
    }
  };

  const handleViewTransactionDetails = (investmentTransaction: any) => {
    setSelectedInvestmentTransaction({
      investmentTransaction,
      type: DetailsType.Edit,
    });
    if (investmentTransaction.fromTransaction) {
      setOriginalInvestmentTransaction({
        investmentTransaction: investmentTransaction.fromTransaction,
        type: DetailsType.Edit,
      });
    }
  };

  const handleAddSingleValuation = async () => {
    try {
      const createdTransaction = await addValuationTransaction(
        newValuationTransaction,
        newValuationTransaction.investmentTransaction.id
      );

      fetchAllInvestmentTransactions();

      if (sendToGL) {
        if (
          newValuationTransaction.entryType === 'TOTAL_VALUATION' &&
          latestValuationTransaction &&
          latestValuationTransaction.investmentTransactionType === 'VALUATION'
        ) {
          newValuationTransaction.totalAmount = Math.abs(
            parseFloat(newValuationTransaction.totalAmount) -
            latestValuationTransaction.totalAmount
          );
        }

        if (newValuationTransaction.entryType === 'NET_EFFECT_VALUATION') {
          sendToJournalEntryDetails(
            {
              ...newValuationTransaction,
              totalAmount: newValuationTransaction.netAmount,
            },
            [createdTransaction.id]
          );
        } else {
          sendToJournalEntryDetails({ ...newValuationTransaction }, [
            createdTransaction.id,
          ]);
        }
      } else {
        closeDrawer();
      }

      setShowApplyMultipleConfirmation(false);
      informationAlert(UPDATE_INVESTMENT_TRANSACTION_SUCCESS, 'success');
    } catch (error) {
      informationAlert(CREATE_INVESTMENT_TRANSACTION_ERROR, 'error');
    }
  };

  const handleAddMultipleValuation = async () => {
    try {
      const multipleValuations: any[] = [];
      let totalAmountForMultipleValuations: number = 0;

      multipleValuations.push(newValuationTransaction);

      if (
        newValuationTransaction.entryType === 'TOTAL_VALUATION' &&
        latestValuationTransaction &&
        latestValuationTransaction.investmentTransactionType === 'VALUATION'
      ) {
        totalAmountForMultipleValuations +=
          parseFloat(newValuationTransaction.totalAmount) -
          latestValuationTransaction.totalAmount;
      } else {
        totalAmountForMultipleValuations +=
          newValuationTransaction.quantity *
          newValuationTransaction.amountPerQuantity;
      }

      matchingPurchaseTransactionsList?.map((t) => {
        const newMatchingValuation = {
          ...newValuationTransaction,
          investmentTransaction: {
            id:
              t.investmentTransactionType === 'CONVERSION'
                ? t.id
                : (t.fromTransaction && t.fromTransaction.id) || t.id,
          },
          totalAmount: t.quantity * newValuationTransaction.amountPerQuantity,
          quantity: t.quantity,
        };

        multipleValuations.push(newMatchingValuation);

        if (
          newValuationTransaction.entryType === 'TOTAL_VALUATION' &&
          t.investmentTransactionType === 'VALUATION'
        ) {
          totalAmountForMultipleValuations +=
            newMatchingValuation.totalAmount - t.totalAmount;
        } else {
          totalAmountForMultipleValuations +=
            t.quantity * newValuationTransaction.amountPerQuantity;
        }
      });

      const createdTransaction = await addMultipleValuationTransaction(
        multipleValuations
      );

      fetchAllInvestmentTransactions();

      if (sendToGL) {
        if (newValuationTransaction.entryType === 'NET_EFFECT_VALUATION') {
          totalAmountForMultipleValuations = 0;
          multipleValuations.forEach((t) => {
            totalAmountForMultipleValuations +=
              t.quantity *
              Math.abs(newValuationTransaction.netAmountPerQuantity);
          });
        }

        sendToJournalEntryDetails(
          {
            ...newValuationTransaction,
            totalAmount: totalAmountForMultipleValuations,
          },
          createdTransaction?.ids
        );
      } else {
        closeDrawer();
      }

      setNewValuationTransaction(undefined);
      setShowApplyMultipleConfirmation(false);
      informationAlert(UPDATE_INVESTMENT_TRANSACTION_SUCCESS, 'success');
    } catch (error) {
      informationAlert(CREATE_INVESTMENT_TRANSACTION_ERROR, 'error');
    }
  };

  const handleNewInvestmentTransactionButtonAction = (
    actionId: string,
    event: any
  ) => {
    const fromTransactionId =
      investmentTransaction.investmentTransactionType === 'PURCHASE' ||
        investmentTransaction.investmentTransactionType === 'CONVERSION'
        ? investmentTransaction.id
        : investmentTransaction.fromTransactionId;

    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          reset(INVESTMENT_TRANSACTION_PURCHASE_FORM_DEFAULT_VALUE);
          reset2(INVESTMENT_TRANSACTION_FOLLOWING_FORM_DEFAULT_VALUE);
          setSelectedInvestmentTransaction({
            investmentTransactionType: '',
            investmentTransaction: undefined,
            viewPurchaseTransactionId: fromTransactionId,
            type: DetailsType.New,
            newView:
              initialPurchaseTransaction?.investmentTransactionType !==
                'PURCHASE' ||
                investmentTransaction.investmentTransactionType !== 'PURCHASE'
                ? InvestmentTransactionNewView.Following
                : InvestmentTransactionNewView.All,
          });
          setInvestmentTransactionType('');
        }
        break;
    }
  };

  const handleNewPortfolioCompanyButtonAction = (
    actionId: string,
    event: any
  ) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedPortfolioCompany({
            portfolioCompany: undefined,
            type: DetailsType.New,
          });
        }
        break;
    }
  };

  const handleViewJournalEntryDetails = async (journalEntryId: any) => {
    const journalEntryDetails = await fetchJournalEntryDetails(journalEntryId);

    if (journalEntryDetails) {
      setSelectedJournalEntry({
        journalEntry: journalEntryDetails,
        type: DetailsType.Edit,
      });
    }
  };

  const handleEditJournalEntryDetails = (journalEntryDetails: any) => {
    if (journalEntryDetails) {
      setSelectedJournalEntry({
        journalEntry: journalEntryDetails,
        type: DetailsType.Edit,
        fromView: FROM_VIEW.INVESTMENT_TRANSACTIONS,
      });
    }
  };

  const continueToJEAfterWriteOff = () => {
    setShowWriteOffNote(false);

    if (createdTransactionId) {
      sendToJournalEntryDetails(getValues(), [createdTransactionId]);
      fetchAllInvestmentTransactions();
      setCreatedTransactionId('');
    }
  };

  const onPortfolioCompanyPanelClose = () => {
    setSelectedPortfolioCompany(initialPortfolioCompany);
    setRefetchPorfolioCompanyFilter(true);
  };

  const onJournalEntryPanelClose = () => {
    setSelectedJournalEntry(initialJournalEntry);
    closeDrawer();
  };

  const selectedSecurityType = watch('securityType');
  const totalAmount = watch('totalAmount');
  const quantity = watch('quantity');
  const amountPerQuantity = watch('amountPerQuantity');
  const totalAmountOther = watch2('totalAmountOther');
  const quantityOther = watch2('quantityOther');
  const amountPerQuantityOther = watch2('amountPerQuantityOther');
  const newTotalAmount = watch2('newTotalAmount');
  const newSelectedSecurityType = watch2('newSecurityType');

  return {
    loading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    register2,
    handleSubmit2,
    errors2,
    control2,
    setValue2,
    followingTransactionList,
    investmentTransactionType,
    initialPurchaseTransaction,
    latestValuationAmount,
    onSubmitFollowingTransaction,
    onSubmitPurchaseTransaction,
    toggleDrawer,
    handleAddSingleValuation,
    handleAddMultipleValuation,
    handleChangeTransactionType,
    handleViewTransactionDetails,
    handleGoBack,
    handleInvestmentTransactionNameValidation,
    handleNewInvestmentTransactionButtonAction,
    handleNewPortfolioCompanyButtonAction,
    setSelectedPurchaseTransactionId,
    closeDrawer,
    selectedSecurityType,
    selectedPurchaseTransactionId,
    showExitConfirmation,
    showApplyMultipleConfirmation,
    keepDrawerOpen,
    matchingPurchaseTransactionsText,
    isNewButtonVisible,
    newInvestmentTransactionTypeList,
    transactionNameUnavailableError,
    totalAmount,
    quantity,
    amountPerQuantity,
    totalAmountOther,
    quantityOther,
    amountPerQuantityOther,
    newTotalAmount,
    newSelectedSecurityType,
    onPortfolioCompanyPanelClose,
    setRefetchPorfolioCompanyFilter,
    refetchPorfolioCompanyFilter,
    selectedPortfolioCompany,
    sendToGL,
    setSendToGL,
    showSaleInfo,
    setShowSaleInfo,
    saleInfo,
    setSaleInfo,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    journalEntryList,
    handleViewJournalEntryDetails,
    arkGlLocked,
    showAssociatedJENotice,
    setShowAssociatedJENotice,
    associatedJENoticeActionList,
    associatedJENoticeContent,
    isSendToGLDisabled,
    sendToGLTooltip,
    portfolioCompanyFilterList,
    selectedPortfolioCompanyFilter,
    setSelectedPortfolioCompanyFilter,
    filteredTransactionList,
    showWriteOffNote,
    continueToJEAfterWriteOff,
    setIsFocused,
    isFocused,
  };
};
type TabProps = {
  isNew?: boolean;
};

export const useTabs = ({ isNew = false }: TabProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const detailsRef = useRef<HTMLInputElement>(null);
  const transactionsRef = useRef<HTMLInputElement>(null);
  const journalEntriesRef = useRef<HTMLInputElement>(null);

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        detailsRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 1:
        transactionsRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 2:
        journalEntriesRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
    }
  };

  return {
    currentTab,
    detailsRef,
    transactionsRef,
    journalEntriesRef,
    handleTabChange,
  };
};
