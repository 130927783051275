export type CategoryProps = {
  companyName: string;
  fund: string;
  securityType: string;
  security: string;
  industrySector: string;
  country: string;
  holdingStatus: string;
  isPublic: string;
  portfolioCustomProperties: PortfolioCompanyCustomProperty[];
};

export type PortfolioCompanyCustomProperty = {
  name: string;
  value: string;
};

export type DataProps = {
  shares: number;
  cost: number;
  fairMarketValue: number;
  unrealizedGainLoss: number;
  realizedValue: number;
  realizedGainLoss: number;
  totalGainLoss: number;
  costPerQuantity: number;
  valuePerQuantity?: number;
  latestValuationDate?: Date | undefined;
  initialInvestmentDate: Date;
  moic: number;
};

export type SoiBaseData = {
  fundId: string;
  portfolioCompanyId: string;
  currencyCode: string;
  categoryProps: CategoryProps;
  dataProps: DataProps;
};

export type SoiGridRow = {
  id: string;
  portfolioCompanyId: string;
  categorySource: string;
  categoryName: string;
  parentCategoryPath: string;
  level: number;
  currencyCode: string;

  [key: string]: any; // rest of data fields are dynamically added
};

export type CategoryFieldDef = {
  name: string;
  headerName: string;
  visible: boolean;
  order: number;
};

export type FilterOptions = {
  portCo: { id: string; name: string }[];
  fund?: { id: string; name: string }[];
};

export type FilterSelection = {
  portCo: string[];
  fund: string[];
};

export type FilterMap = {
  dependencies: { [key: string]: string };
  listItems: {
    [key: string]: {
      [key: string]: string[];
    };
  };
};

export type ValueFieldDef = CategoryFieldDef & {
  numberFormat:
    | 'Integer-use_commas'
    | 'Currency.0'
    | 'Currency.2'
    | 'Decimal-use-comas.2'
    | 'String';
};

export const LevelColors = [
  '#3944bc',
  '#281e5d',
  '#016064',
  '#241571',
  '#2c3e4c',
  '#757c88',
  '#48AAAD',
  '#0892c2',
];
