import {
  Box,
  Card,
  CardContent,
  FormControl as MUIFormControl,
  Grid,
  MenuItem as MUIMenuItem,
  Select as MUISelect,
  styled,
  TextField as MUITextfield,
  Typography,
} from '@mui/material';

import { CashReceiptCardTypes } from './CashReceipts.constants';

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gridTemplateColumns: '15% 61% 23%',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

export const SelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),

  [`& .MuiOutlinedInput-root`]: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: '10px',
    width: '320px',
  },
  [`& .MuiTextField-root .MuiInputLabel-shrink`]: {
    transform: 'translate(14px, 0px) scale(0.75)',
  },
  [`& .MuiAutocomplete-root .MuiOutlinedInput-root`]: {
    padding: '0',
  },
  [`& .MuiTextField-root .MuiFormLabel-root`]: {
    top: '-7px',
  },
}));

export const ButtonStartBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
}));

export const ButtonEndBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
}));

export const BulkEditBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '60% 40%',
  gap: theme.spacing(2),
}));

export const CashReceiptsDataBox = styled(Box)<{
  hidden?: boolean;
  gapSize?: number;
}>(({ theme, hidden, gapSize = 1 }) => ({
  height: '100%',
  display: hidden === true ? 'none' : 'grid',
  gap: theme.spacing(gapSize),
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto auto 1fr',
}));

export const CashReceiptsCardListGrid = styled(Grid)(() => ({
  justifyContent: 'flex-start',
  flexWrap: 'nowrap',
  paddingBottom: '16px',
  overflowY: 'auto',
  overflowX: 'auto',
  display: 'flex',
  height: '100%',
  gap: '16px',
}));

export const BankSummaryCard = styled(Card)<{
  type: string;
}>(({ theme, type }) => {
  const baseStyles = {
    minWidth: '250px',
    maxWidth: '450px',
    width: '100%',
    padding: '8px',
    borderRadius: '10px',
  };

  let cardTypeStyles = {};
  const cardSkyBlue = theme.palette.common.cardSkyBlue;
  const cardAmber = theme.palette.common.cardAmber;

  if (type === CashReceiptCardTypes.TotalCapitalCalled) {
    cardTypeStyles = {
      background: theme.palette.common.cardPaleBlue,
      backgroundImage: `linear-gradient(
        -33.52deg,
        ${cardSkyBlue}4D -0.92%,
        ${cardSkyBlue}4D 7.16%,
        ${cardSkyBlue}00 30.26%
      )`,
    };
  } else if (type === CashReceiptCardTypes.TotalAmountPaid) {
    cardTypeStyles = {
      background: theme.palette.common.cardMintCream,
      backgroundImage: `linear-gradient(
        -33.52deg,
        ${cardSkyBlue}4D -0.92%,
        ${cardSkyBlue}4D 7.16%,
        ${cardSkyBlue}00 30.26%
      )`,
    };
  } else if (type === CashReceiptCardTypes.TotalRemainingDue) {
    cardTypeStyles = {
      backgroundImage: `linear-gradient(
        -33.52deg,
        ${cardAmber}4D -0.92%,
        ${cardAmber}4D 7.16%,
        ${cardAmber}26 30.26%
      )`,
    };
  }

  const mergedStyles = { ...baseStyles, ...cardTypeStyles };

  return mergedStyles;
});

export const CashReceiptCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: '16px !important',
}));

export const CashReceiptCardHeader = styled(Typography)(({ theme }) => ({
  md: '1.5',
}));

export const LineTextField = styled(MUITextfield, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isAmountPaid',
})<{
  error?: boolean;
  isAmountPaid?: boolean;
}>(({ theme, error }) => ({
  border: error ? '1px solid red' : '0',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0',
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    textIndent: '0px',
    textAlign: 'right',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    paddingLeft: '30px',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '1px',
  },

  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const FilterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  error?: boolean;
}>(({ theme, error }) => ({
  width: '100%',
  border: error ? '1px solid red' : '0',
  '.MuiInput-input:focus': {
    background: 'none',
  },
}));

export const FormControlCheckbox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  error?: boolean;
}>(({ theme, error }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginLeft: 0,
  marginRight: '0',
  width: '100%',
  border: error ? '1px solid red' : '0',
  '.MuiFormControlLabel-label': {
    fontWeight: 'bold',
  },
}));

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));

export const CheckboxContent = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'grey',
})<{
  grey?: boolean;
}>(({ theme, grey }) => ({
  fontSize: '0.9rem',
  color: grey ? theme.palette.text.disabled : theme.palette.common.black,
}));

export const DialogBox = styled(Box)(({ theme }) => ({
  textAlign: 'left',
}));

export const LinkBox = styled(Box)(({ theme }) => ({
  height: '0',
}));
