import { useContext, useEffect, useState } from 'react';
import {
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';

import { AppContext } from '../../../../core/context/appContextProvider';
import { getArkAccounts } from '../../../../services/arkGL.service';
import { updateBankTransactions } from '../../../../services/bank.service';
import {
  FindLengthOfFraction,
  FormatIntegerWithCurrencyOffset,
} from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import {
  BankAccount,
  BankTransaction,
  BankTransactionJELineItem,
  JEPreviewFormatBanksJournalEntry,
  JEPreviewFormatBankTransaction,
  UpdateBankTransactionsPayload,
} from '../../../../utils/types/bank.type';
import { LoadingStatus } from '../../../../utils/types/form.type';
import { BANKS_JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE } from '../../../banks/bankFeeds/BankFeedList.defaults';
import { GET_GL_ACCOUNT_LIST_ERROR } from '../../accounts/accountList/AccountList.constants';

type Props = {
  selectedBankTransaction: BankTransaction;
  bankAccountList: BankAccount[];
  onDetailsClose: () => void;
  fetchBankTransactions: () => void;
};

export const useBanksJournalEntryPreview = ({
  selectedBankTransaction,
  bankAccountList,
  onDetailsClose,
  fetchBankTransactions,
}: Props) => {
  const { informationAlert } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);

  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [totalError, setTotalError] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>('');
  const [accountList, setAccountList] = useState<any[]>([]);
  const [currencyDecimal, setCurrencyDecimal] = useState<number>(
    FindLengthOfFraction(selectedBankTransaction.currency)
  );

  useEffect(() => {
    setCurrencyDecimal(FindLengthOfFraction(selectedBankTransaction.currency));
  }, [selectedBankTransaction]);

  useEffectAsync(
    async (isCanceled) => {
      await fetchAccountList(isCanceled);
    },
    [selectedFundId, selectedBankTransaction]
  );

  const fetchAccountList = async (isCanceled?: () => boolean) => {
    if (selectedFundId || selectedBankTransaction.fundId) {
      setIsLoading(LoadingStatus.Loading);
      try {
        if (isCanceled?.()) return;

        const response = await getArkAccounts(
          selectedFundId || selectedBankTransaction.fundId
        );

        const accounts = response.items
          .map((account: any) => {
            return {
              id: account.id,
              name: `${account.number} - ${account.name}`,
              isEntityRequired: account.isEntityRequired,
            };
          })
          .sort();

        accounts.push({
          id: '+',
          name: 'Create new account',
          isEntityRequired: false,
        });

        setAccountList(accounts);
      } catch (e) {
        informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
      }
      setIsLoading(undefined);
    } else {
      setAccountList([]);
    }
  };

  useEffect(() => {
    setPreviewTitle(
      `${selectedBankTransaction.bankAccountName} - ${
        selectedBankTransaction.type === 'INFLOW' ? 'Inflow' : 'Outflow'
      }: ${FormatIntegerWithCurrencyOffset(
        Math.abs(selectedBankTransaction.amount),
        selectedBankTransaction.currency
      )}`
    );
  }, [selectedBankTransaction]);

  const formatJournalEntry = () => {
    const journalEntryLineItems: JEPreviewFormatBanksJournalEntry[] = [
      {
        accountId:
          bankAccountList.find(
            (acc) => acc.id === selectedBankTransaction.bankAccountId
          )?.glAccountId || '',
        amount: selectedBankTransaction.amount / Math.pow(10, currencyDecimal),
        type: selectedBankTransaction.type === 'INFLOW' ? 'DEBIT' : 'CREDIT',
        memo: selectedBankTransaction.description || '',
        isBankTransactionEntry: true,
      },
    ];

    if (selectedBankTransaction.journalEntry?.length) {
      selectedBankTransaction.journalEntry.map((entry) => {
        journalEntryLineItems.push({
          accountId: entry.accountId,
          type: entry.type,
          memo: entry.memo
            ? entry.memo
            : selectedBankTransaction.description || '',
          isBankTransactionEntry: false,
          amount: entry.amount / Math.pow(10, currencyDecimal),
        });
      });
    } else {
      journalEntryLineItems.push(BANKS_JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE);
    }

    return journalEntryLineItems;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    control,
    setValue,
    watch,
    reset,
  } = useForm<JEPreviewFormatBankTransaction>({
    defaultValues: {
      date: selectedBankTransaction.date,
      fundId: selectedBankTransaction.fundId,
      ledgerId: selectedBankTransaction.ledgerId,
      memoEntity: selectedBankTransaction.memoEntityId
        ? [
            {
              id: selectedBankTransaction.memoEntityId
                ? selectedBankTransaction.memoEntityId
                : undefined,
              name: selectedBankTransaction.memoEntityName
                ? selectedBankTransaction.memoEntityName
                : undefined,
            },
          ]
        : [],
      journalEntry: formatJournalEntry(),
      memo: selectedBankTransaction.memo
        ? selectedBankTransaction.memo
        : selectedBankTransaction.description,
    },
  });

  const { isDirty } = useFormState({ control });

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: 'journalEntry',
  });

  const fieldValues = useWatch({
    name: 'journalEntry',
    control,
  });

  const watchFieldValues = watch('journalEntry');

  const controlledFields = fieldValues?.map((field: any, index: number) => {
    return {
      ...field,
      ...watchFieldValues[index],
    };
  });

  const totalDebit = controlledFields
    ? controlledFields.reduce(
        (total: number, current: BankTransactionJELineItem) => {
          if (current.amount && current.type === 'DEBIT') {
            return total + Math.abs(current.amount);
          } else {
            return total;
          }
        },
        0
      )
    : selectedBankTransaction?.journalEntry?.reduce(
        (total: number, current: BankTransactionJELineItem) => {
          if (current.amount && current.type === 'DEBIT') {
            return total + Math.abs(current.amount);
          } else {
            return total;
          }
        },
        0
      );

  const totalCredit = controlledFields
    ? controlledFields.reduce(
        (total: number, current: BankTransactionJELineItem) => {
          if (current.amount && current.type === 'CREDIT') {
            return total + Math.abs(current.amount);
          } else {
            return total;
          }
        },
        0
      )
    : selectedBankTransaction?.journalEntry?.reduce(
        (total: number, current: BankTransactionJELineItem) => {
          if (current.amount && current.type === 'CREDIT') {
            return total + Math.abs(current.amount);
          } else {
            return total;
          }
        },
        0
      );

  const handleAddLine = () => {
    const line = {
      ...BANKS_JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
    };

    line.memo = selectedBankTransaction.description || '';

    append(line);
  };

  const handleDeleteLine = (index: number) => {
    const currentLength = watchFieldValues.length;

    if (
      !watchFieldValues[index].accountId &&
      !watchFieldValues[index].memo &&
      !watchFieldValues[index].amount &&
      index === currentLength - 1
    ) {
      if (currentLength > 2) {
        const current = [...watchFieldValues];

        const updated = current.filter((field: any, fieldIndex: number) => {
          if (index !== fieldIndex) {
            return {
              ...field,
              ...watchFieldValues[index],
            };
          }
        });

        setValue('journalEntry', [...updated]);
      }
    } else {
      update(index, {
        ...BANKS_JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
      });
      setValue(`journalEntry.${index}.entityIds`, []);
    }
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    onDetailsClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const onSubmit = async (data: any) => {
    if (totalError) return;

    const memoEntityId: string = data.memoEntity[0]?.id || '';

    const journalEntryLineItems: BankTransactionJELineItem[] =
      data.journalEntry.reduce(
        (
          acc: BankTransactionJELineItem[],
          lineItem: JEPreviewFormatBanksJournalEntry
        ): BankTransactionJELineItem[] => {
          if (
            lineItem.accountId &&
            lineItem.type &&
            !lineItem.isBankTransactionEntry
          ) {
            acc.push({
              accountId: lineItem.accountId,
              amount: Math.round(
                lineItem.amount * Math.pow(10, currencyDecimal)
              ),
              type: lineItem.type,
              memo: lineItem.memo ? lineItem.memo : '',
              entityIds: memoEntityId ? [memoEntityId] : [],
            });
          }
          return acc;
        },
        []
      );

    const payload: UpdateBankTransactionsPayload = {
      id: selectedBankTransaction.id,
      status: selectedBankTransaction.status,
      isPlaid: selectedBankTransaction.isPlaid,
      memo: data.memo,
      journalEntryLineItem: journalEntryLineItems,
    };

    if (memoEntityId) {
      payload.memoEntityId = memoEntityId;
    }

    try {
      setIsLoading(LoadingStatus.Updating);
      await updateBankTransactions([payload]);
      setIsLoading(undefined);
      fetchBankTransactions();
      closeDrawer();
    } catch (e) {
      setIsLoading(undefined);
      informationAlert('Error saving Journal Entry Preview', 'error');
    }
  };

  useEffect(() => {
    reset();
    setTotalError(false);
  }, [reset]);

  useEffect(() => {
    Math.round(totalCredit * Math.pow(10, currencyDecimal)) !==
    Math.round(totalDebit * Math.pow(10, currencyDecimal))
      ? setTotalError(true)
      : setTotalError(false);

    if (!totalCredit || !totalDebit) {
      setTotalError(true);
    }
  }, [totalDebit, totalCredit]);

  return {
    isLoading,
    previewTitle,
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    controlledFields,
    fieldValues,
    accountList,
    totalDebit,
    totalCredit,
    totalError,
    handleAddLine,
    handleDeleteLine,
    showExitConfirmation,
    closeDrawer,
    keepDrawerOpen,
    toggleDrawer,
    onSubmit,
  };
};
