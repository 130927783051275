import { cloneDeep } from 'lodash';
import { useContext, useMemo, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import {
  getAllArkAccounts,
  getAllEntities,
  getArkLedgers,
  getReportTrialBalanceDetails,
} from '../../../services/arkGL.service';
import { getBankRules, getBanks } from '../../../services/bank.service';
import { getCapitalCalls } from '../../../services/capitalCalls.service';
import { getFundNames } from '../../../services/fund.service';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import {
  BankAccount,
  BankRule,
  BanksTrialBalanceParams,
  GlAccountDetails,
  nameIdFundPair,
  NameIdPair,
  nameIdTypePair,
} from '../../../utils/types/bank.type';
import { CapitalCalls } from '../../../utils/types/capitalCalls.type';
import { GET_ENTITY_LIST_ERROR } from '../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import { BANK_STATUS_FILTER_LIST } from '../bankFeeds/BankFeedList.defaults';

export const useBankfilters = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  triggerFetchTrialBalance: number
) => {
  const { informationAlert } = useContext(AppContext);

  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([]);
  const [fundList, setFundList] = useState<NameIdPair[]>([]);
  const [ledgerList, setLedgerList] = useState<nameIdFundPair[]>([]);
  const [CoAList, setCoAList] = useState<GlAccountDetails[]>([]);
  const [memoEntityList, setMemoEntityList] = useState<nameIdTypePair[]>([]);
  const [memoEntityFilterList, setMemoEntityFilterList] = useState<any[]>([]);
  const [capitalCallList, setCapitalCallList] = useState<any[]>([]);
  const [glAccountDetails, setGLAccountDetails] = useState<any[]>([]);
  const [bankRulesResponse, setBankRulesResponse] = useState<BankRule[]>([]);

  const fetchAllBankAccounts = async (isCanceled?: () => boolean) => {
    try {
      const allBanksResponse = await getBanks();

      if (isCanceled?.()) return;

      setBankAccountList(allBanksResponse || null);
    } catch (e) {
      informationAlert('Error getting bank accounts list', 'error');
    }
  };

  useEffectAsync(
    async (isCanceled) => {
      if (!bankAccountList.length || !fundList.length) return;

      //Temp FE filter for bank accounts assigned to funds not visible to client => pending BE updt
      const availableBankAccounts = bankAccountList.filter(
        (bankAcc) => !!fundList?.find((fund) => fund.id === bankAcc.fundId)
      );

      const uniqueLedgerIds = new Set<string>();
      const uniqueAccountIds = new Set<string>();

      availableBankAccounts.forEach((acct) => {
        if (acct.ledgerId && acct.glAccountId) {
          uniqueLedgerIds.add(acct.ledgerId);
          uniqueAccountIds.add(acct.glAccountId);
        }
      });

      const apiParams: BanksTrialBalanceParams = {
        ledgerIds: Array.from(uniqueLedgerIds),
        accountIds: Array.from(uniqueAccountIds),
        startDate: '',
        endDate: DateTimeFormat.getReversedDate(new Date()),
      };

      try {
        const response = await getReportTrialBalanceDetails(apiParams);

        if (isCanceled()) return;
        setGLAccountDetails(response.accounts);
      } catch (e: any) {
        if (e.response?.status === 400) return;
        informationAlert('Error getting GL Account balances', 'error');
      }
    },
    [bankAccountList, fundList, triggerFetchTrialBalance]
  );

  const fetchAllFundNames = async (isCanceled?: () => boolean) => {
    try {
      const fundsList = await getFundNames();

      if (isCanceled?.()) return;

      setFundList(fundsList);
    } catch (e) {
      informationAlert('Error getting funds list', 'error');
    }
  };

  const fetchArkLedgerNames = async (isCanceled?: () => boolean) => {
    try {
      const ledgersRes = await getArkLedgers();

      if (isCanceled?.()) return;

      setLedgerList(ledgersRes.items);
    } catch (e) {
      informationAlert('Error getting ledger list', 'error');
    }
  };

  const fetchAllCoANames = async (isCanceled?: () => boolean) => {
    try {
      const accountsRes = await getAllArkAccounts();

      if (isCanceled?.()) return;

      setCoAList(accountsRes.items);
    } catch (e) {
      informationAlert('Error getting accounts list', 'error');
    }
  };

  const fetchEntityList = async (isCanceled?: () => boolean) => {
    try {
      const response = await getAllEntities();

      if (isCanceled?.()) return;

      const groupedEntities: { [key: string]: any[] } = {
        fund: [],
        investor: [],
        portCo: [],
        memoEntity: [],
      };

      const response1 = cloneDeep(response);

      response1.items.forEach((entity: any) => {
        switch (entity.type) {
          case 'Fund':
            groupedEntities.fund.push(entity);
            break;
          case 'Investor':
            groupedEntities.investor.push(entity);
            break;
          case 'PortfolioCompany':
            entity.type = 'Portfolio Company';
            groupedEntities.portCo.push(entity);
            break;
          case 'GeneralEntity':
            entity.type = 'Memo Entity';
            groupedEntities.memoEntity.push(entity);
            break;
          default:
            break;
        }
      });

      Object.keys(groupedEntities).forEach((key) => {
        groupedEntities[key].sort((a, b) => a.name.localeCompare(b.name));
      });

      const groupedEntityList = [
        ...groupedEntities.fund,
        ...groupedEntities.investor,
        ...groupedEntities.portCo,
        ...groupedEntities.memoEntity,
      ];

      setMemoEntityList(groupedEntityList);

      const grouped: { type: string; entities: any[] }[] = [
        {
          type: 'Fund',
          entities: [],
        },
        {
          type: 'Investor',
          entities: [],
        },
        {
          type: 'Portfolio Company',
          entities: [],
        },
        {
          type: 'Memo Entity',
          entities: [],
        },
        {
          type: 'None',
          entities: [],
        },
      ];

      if (response.items.length >= 1) {
        response.items.forEach((entity: any) => {
          if (entity.type === 'PortfolioCompany') {
            entity.type = 'Portfolio Company';
          }
          if (entity.type === 'GeneralEntity') {
            entity.type = 'Memo Entity';
          }
        });
      }

      response.items.map((entity: any) => {
        grouped
          .find((list) => list.type === entity.type)
          ?.entities.push(entity);
      });

      grouped.forEach((group) => {
        group.entities.sort((a, b) => a.name.localeCompare(b.name));
      });

      grouped[4].entities.push({
        id: 'null',
        type: 'None',
        name: 'No Entity',
        entityId: undefined,
      });

      setMemoEntityFilterList([
        ...grouped[0].entities,
        ...grouped[1].entities,
        ...grouped[2].entities,
        ...grouped[3].entities,
        ...grouped[4].entities,
      ]);
      if (isCanceled?.()) return;
    } catch (e) {
      informationAlert(GET_ENTITY_LIST_ERROR, 'error');
    }
  };

  const fetchCapitalCallList = async (isCanceled?: () => boolean) => {
    try {
      const response: CapitalCalls[] = await getCapitalCalls();

      if (isCanceled?.()) return;

      if (response) {
        const list = response
          .filter((c) => c.status === 'PUBLISHED')
          .map((c) => {
            return {
              ...c,
              label: `${c.name} - ${DateTimeFormat.shortDateString(
                c.date as string
              )} - ${c.fundName}`,
            };
          })
          .sort((a, b) => {
            const dateA = a.date ? new Date(a.date).getTime() : 0;
            const dateB = b.date ? new Date(b.date).getTime() : 0;

            return dateB - dateA;
          });

        setCapitalCallList(list);
      }
    } catch (e) {
      informationAlert('Error getting capital call list', 'error');
    }
  };

  const fetchAllBankRules = async (isCanceled?: () => boolean) => {
    try {
      const allBankRulesResponse = await getBankRules();

      if (isCanceled?.()) return;

      setBankRulesResponse(allBankRulesResponse);
    } catch (e) {
      informationAlert('Error getting bank rules', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const selectedBankAccountsList = useMemo(
    () => bankAccountList?.map((item) => item.id),
    [bankAccountList]
  );

  const selectedFundsList = useMemo(
    () => fundList?.map((item) => item.id),
    [fundList]
  );

  const selectedCoAList = useMemo(
    () => CoAList?.map((item) => item.id),
    [CoAList]
  );

  const selectedMemoEntityFilterList = useMemo(
    () => memoEntityFilterList?.map((item: any) => item.id),
    [memoEntityFilterList]
  );

  const selectedStatusList = useMemo(
    () => BANK_STATUS_FILTER_LIST?.map((item) => item.id),
    [BANK_STATUS_FILTER_LIST]
  );

  const fetchAllFilters = async (isCanceled?: () => boolean) => {
    const promises = [
      fetchAllBankAccounts(isCanceled),
      fetchAllFundNames(isCanceled),
      fetchArkLedgerNames(isCanceled),
      fetchAllCoANames(isCanceled),
      fetchEntityList(isCanceled),
      fetchCapitalCallList(isCanceled),
      // fetchAllBankRules(isCanceled),
    ];

    await Promise.all(promises);
  };

  return {
    fetchAllFilters,
    fetchAllBankAccounts,
    bankAccountList,
    fundList,
    ledgerList,
    CoAList,
    memoEntityList,
    memoEntityFilterList,
    capitalCallList,
    selectedBankAccountsList,
    selectedFundsList,
    selectedCoAList,
    selectedMemoEntityFilterList,
    selectedStatusList,
    glAccountDetails,
  };
};
