import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TrashIcon from '@mui/icons-material/DeleteOutline';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import IconRightArrow from '../../../../assets/images/icons/icon_right_arrow.svg';
import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import SplitButtonSubmit from '../../../../components/Buttons/SplitButton/SplitButton.Submit';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import {
  PanelBoxExpanded,
  StyledBackdrop,
  Title,
  TitleBox,
} from '../../../../components/DetailPanel/DetailPanel.styles';
import FileSelector from '../../../../components/FileSelector/FileSelector';
import ImgIcon from '../../../../components/ImgIcon/ImgIcon';
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import DeleteConfirmationDialog from '../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import JEAccountFilter from '../../../../components/Selectors/JEAccountFilter/JEAccountFilter';
import JEEntityFilter from '../../../../components/Selectors/JEEntityFilter/JEEntityFilter';
import JEFundFilter from '../../../../components/Selectors/JEFundFilter/JEFundFilter';
import JELedgerFilter from '../../../../components/Selectors/JELedgerFilter/JELedgerFilter';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import TextField from '../../../../components/TextField/TextField';
import DecimalFormatCustom from '../../../../components/TextFieldNumeric/DecimalFormat';
import { FROM_VIEW } from '../../../../utils/constants/common.constants';
import {
  DateTimeFormat,
  NumberFormat,
} from '../../../../utils/helpers/format.helper';
import {
  JournalEntryStatusTypes,
  SelectedJournalEntry,
} from '../../../../utils/types/arkGLJournalEntry.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import { ImageItem } from '../../../../utils/types/listItems';
import { InvestmentTransactionDetails } from '../../../fund/InvestmentTransactions/investmentTransactionDetails/investmentTransactionDetails';
import { AccountDetails } from '../../accounts/accountDetails/AccountDetails';
import JournalEntryPostDialog from '../journalEntryPostDialog/journalEntryPostDialog';
import { useJournalEntryDetails } from './JournalEntryDetails.hooks';
import {
  AttachmentButtonBox,
  AttachmentListBox,
  ButtonBox,
  DatePicker,
  DocumentLinkBox,
  FileSelectorWrapper,
  FormControlCheckbox,
  GridBlue,
  IndexLabel,
  Label,
  Line,
  LineCell,
  LineDescription,
  LineHeader,
  LineItemBox,
  LineItemList,
  LineTextField,
  LinkBox,
  LinkButton,
  StatusBox,
  StyledLateralBox,
} from './JournalEntryDetails.styles';

interface Props extends RouteProps {
  onDetailClose: Function;
  onSuccessfulPostClose?: Function;
  selectedJournalEntry: SelectedJournalEntry;
  setSelectedJournalEntry: Function;
  fetchAllJournalEntries: Function;
  fundId?: string;
  unsavedChangesContent?: string;
  unsavedChangesTitle?: string;
}

type Mapping = Record<JournalEntryStatusTypes, string>;

const StatusType: Mapping = {
  draft: 'yellow',
  posted: 'green',
};

export const JournalEntryDetails: React.FC<Props> = ({
  onDetailClose,
  onSuccessfulPostClose,
  selectedJournalEntry,
  setSelectedJournalEntry,
  fetchAllJournalEntries,
  fundId,
  unsavedChangesContent,
  unsavedChangesTitle,
}: Props) => {
  const { type, journalEntry, multiView, singleView } = selectedJournalEntry;

  const isNewJournalEntry = type === DetailsType.New;
  const [displayMemoMessage, setDisplayMemoMessage] = useState(false);

  const {
    isLoading,
    handleSubmit,
    setValue,
    control,
    fields,
    fieldValues,
    controlledFields,
    totalCredit,
    totalDebit,
    handleClearAllLines,
    handleConfirmClearAll,
    handleCancelClearAll,
    handleAddLine,
    handleDeleteLine,
    showClearAllConfirmation,
    toggleDrawer,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    onSubmit,
    sendToSOI,
    setSendToSOI,
    handleBulkOptionClick,
    status,
    accountList,
    selectedFundId,
    setSelectedFundId,
    totalError,
    handleAddNewAccount,
    selectedAccount,
    onAccountDetailsPanelClose,
    refetchAccountFilter,
    selectedLedgerCurrency,
    setSelectedLedgerCurrency,
    selectedLedgerDecimal,
    setSelectedLedgerDecimal,
    selectedInvestmentTransaction,
    setSelectedInvestmentTransaction,
    onInvestmentTransactionPanelClose,
    showUpdateLedgerConfirmation,
    updateLedgerCancel,
    updateLedgerConfirm,
    handleLedgerUpdate,
    handleSaveButtonAction,
    saveButtonOptions,
    lockIsAdjusting,
    ledgerList,
    groupedEntityList,
    selectedLedger,
    onDeleteAttachment,
    onDeleteCancel,
    onDeleteConfirm,
    selectedDeleteAttachment,
    showDeleteAttachmentConfirmation,
    handleDownloadAttachment,
    toggleAttachmentListView,
    setToggleAttachmentListView,
    isSendToSOIDisabled,
    sendToSOITooltip,
    postAction,
    showPostDialog,
    journalEntriesToPost,
    onPostCancel,
    onPostComplete,
    showAssociatedInvTransactionNotice,
    setShowAssociatedInvTransactionNotice,
    reviewSuggestedInvestmentTransaction,
  } = useJournalEntryDetails({
    journalEntry,
    selectedJournalEntry,
    onClose: onDetailClose,
    onSuccessfulPostClose: onSuccessfulPostClose,
    fetchAllJournalEntries,
    isNewJournalEntry,
    setSelectedJournalEntry,
    fundId,
  });

  const bulkActionOptions: ImageItem[] = [
    {
      id: 'copy',
      text: 'Copy',
      icon: <CopyAllIcon fontSize="small" />,
      optionsSelected: 0,
    },
    {
      id: 'reverse',
      text: 'Reverse',
      icon: <FlipCameraAndroidIcon fontSize="small" />,
      optionsSelected: 0,
      hidden: journalEntry?.state !== 'POSTED',
    },
    {
      id: 'post_to_gl',
      text: 'Post to GL',
      icon: <PostAddIcon fontSize="small" />,
      optionsSelected: 0,
      hidden: journalEntry?.state === 'POSTED',
    },
  ];

  return (
    <>
      <DetailPanel
        id="detail_panel_journal_entry"
        title={
          isNewJournalEntry
            ? 'Add Journal Entry'
            : `Journal Entry #${journalEntry?.number}`
        }
        open={Boolean(type)}
        hasTabsPanel={false}
        onClose={toggleDrawer}
        isGridDisplay={true}
        customTitleBox={!!multiView}
      >
        <StyledLateralBox overflow="scroll">
          {multiView && (
            <TitleBox>
              <Title>
                Add Journal Entry <ImgIcon icon={IconRightArrow} />
              </Title>
              <CloseIcon color="primary" onClick={toggleDrawer} />
            </TitleBox>
          )}
          <form
            autoComplete="off"
            onSubmit={handleSubmit((data, e) => onSubmit(data, e))}
            noValidate
          >
            <PanelBoxExpanded>
              <Grid container spacing={2}>
                <Grid item xs={5} sm={2}>
                  <StatusBox>
                    {status && (
                      <StatusLabel
                        color={
                          StatusType[
                            status?.toLowerCase() as keyof typeof StatusType
                          ]
                        }
                        label={status}
                      />
                    )}
                  </StatusBox>
                </Grid>
                <Grid item xs={5} sm={10}>
                  {journalEntry && journalEntry.id && (
                    <ButtonBox>
                      <SplitButton
                        id={'btn_bulk_action_options'}
                        options={bulkActionOptions}
                        handleOptionClick={handleBulkOptionClick}
                        ariaLabelMessage="Select bulk action option"
                        hidden={false}
                      />
                    </ButtonBox>
                  )}
                </Grid>
                <GridBlue item xs={12} sm={1.6} md={1.6} lg={1.6}>
                  <Controller
                    name="date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label="Journal Date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="txt_journal_date"
                            label="Journal Date"
                            aria-describedby="date"
                            placeholder="Journal Date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message ?? ''}
                            disabled={journalEntry?.state === 'POSTED'}
                          />
                        )}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        value={value ? DateTimeFormat.shortDate(value) : null}
                      />
                    )}
                    rules={{
                      required: 'Date is required.',
                    }}
                    control={control}
                  />
                </GridBlue>
                <GridBlue item xs={12} sm={2} md={2} lg={2}>
                  <JEFundFilter
                    size="small"
                    id={'fund_filter'}
                    idSelectOnly
                    value={selectedFundId || fundId || journalEntry?.fundId}
                    disabled={
                      (selectedJournalEntry.fromView !==
                        FROM_VIEW.INVESTMENT_TRANSACTIONS &&
                        !!fundId) ||
                      journalEntry?.state === 'POSTED'
                    }
                    onChange={(fund: any) => {
                      setSelectedFundId(fund);
                    }}
                    placeholder="Fund Name"
                  />
                </GridBlue>
                <GridBlue item xs={12} sm={2} md={2} lg={2}>
                  <Controller
                    name="ledgerId"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <JELedgerFilter
                        size="small"
                        id={'gl_filter'}
                        idSelectOnly
                        value={
                          value || (selectedLedger ? selectedLedger.id : null)
                        }
                        onChange={handleLedgerUpdate}
                        ledgerList={ledgerList}
                        loading={isLoading}
                        setSelectedLedgerCurrency={setSelectedLedgerCurrency}
                        setSelectedLedgerDecimal={setSelectedLedgerDecimal}
                        disabled={journalEntry?.state === 'POSTED'}
                        error={error}
                        placeholder="GL Name"
                      />
                    )}
                    rules={{
                      required: 'GL is required',
                    }}
                    control={control}
                  />
                </GridBlue>
                <GridBlue item xs={12} sm={3.4} md={3.4} lg={3.4}>
                  <TextField
                    id="journal_number"
                    label="Journal No."
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={journalEntry?.number}
                    disabled={true}
                    hidden={isNewJournalEntry}
                  />
                </GridBlue>
                <GridBlue item xs={12} sm={3} md={3} lg={3}>
                  {!multiView && (
                    <Controller
                      name="adjustingJournalEntry"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControlCheckbox>
                          <FormControlLabel
                            id="adjusting_entry"
                            control={
                              <Checkbox
                                checked={value === true ? true : false}
                                onChange={onChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="Is Adjusting Journal Entry?"
                            disabled={
                              journalEntry?.state === 'POSTED' ||
                              lockIsAdjusting === true
                            }
                          />
                        </FormControlCheckbox>
                      )}
                      control={control}
                    />
                  )}
                </GridBlue>
                <Grid item xs={12}>
                  <LineItemBox>
                    <LineItemList>
                      <Grid container>
                        <LineHeader>
                          <LineCell item xs={0.3} br>
                            <IndexLabel bold>#</IndexLabel>
                          </LineCell>
                          <LineCell item xs={2.5} br>
                            <Label bold>ACCOUNT</Label>
                          </LineCell>
                          <LineCell item xs={1.8} br>
                            <Label bold number>
                              {selectedLedgerCurrency ||
                              journalEntry?.currencyName
                                ? `DEBITS (${
                                    selectedLedgerCurrency ||
                                    journalEntry?.currencyName
                                  })`
                                : 'DEBITS'}
                            </Label>
                          </LineCell>
                          <LineCell item xs={1.8} br>
                            <Label bold number>
                              {selectedLedgerCurrency ||
                              journalEntry?.currencyName
                                ? `CREDITS (${
                                    selectedLedgerCurrency ||
                                    journalEntry?.currencyName
                                  })`
                                : 'CREDITS'}
                            </Label>
                          </LineCell>
                          <LineCell item xs={2.8} br>
                            <Label bold>DESCRIPTION</Label>
                          </LineCell>
                          <LineCell item xs={2.2}>
                            <Label bold>MEMO TAGS</Label>
                            <LineCell>
                              {displayMemoMessage && (
                                <Label bold style={{ color: 'red' }}>
                                  There is a limit of 10 memo tags per journal
                                  entry line.
                                </Label>
                              )}
                            </LineCell>
                          </LineCell>
                          <LineCell item xs={0.5}></LineCell>
                        </LineHeader>
                        {(isNewJournalEntry ||
                          journalEntry?.state === 'DRAFT') &&
                          controlledFields.map((field: any, index: number) => {
                            return (
                              <Line key={index}>
                                <LineCell item xs={0.3} br>
                                  <IndexLabel>{index + 1}</IndexLabel>
                                </LineCell>
                                <LineCell item xs={2.5} br>
                                  <Controller
                                    name={`lineItems.${index}.accountId`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <JEAccountFilter
                                        {...field}
                                        size="small"
                                        id={`account_${index}`}
                                        idSelectOnly
                                        value={
                                          fieldValues[index]?.accountId
                                            ? value
                                            : ''
                                        }
                                        handleAddNewAccount={() =>
                                          handleAddNewAccount(index)
                                        }
                                        accountList={accountList}
                                        loading={isLoading}
                                        refetch={
                                          refetchAccountFilter ||
                                          Boolean(selectedFundId)
                                        }
                                        onChange={(accountId) => {
                                          onChange(accountId);
                                          const account = accountList.find(
                                            (acc) => acc.id === accountId
                                          );
                                        }}
                                        setValue={setValue}
                                        setValueField={`lineItems.${index}.isEntityRequired`}
                                        error={error}
                                      />
                                    )}
                                    rules={{
                                      required: !!fieldValues[index]?.amount,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={1.8} br>
                                  <Controller
                                    name={`lineItems.${index}.amount`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <LineTextField
                                        {...field}
                                        id={`debit_${index}`}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        number
                                        value={
                                          fieldValues[index]?.entryType ===
                                          'DEBIT'
                                            ? value
                                            : ''
                                        }
                                        onChange={(e) => {
                                          let newValue: any = parseFloat(
                                            e.target.value
                                          ) as number;

                                          if (newValue) {
                                            newValue =
                                              newValue > 0
                                                ? newValue
                                                : fieldValues[index].amount;
                                          }

                                          if (newValue >= 0) {
                                            setValue(
                                              `lineItems.${index}.entryType`,
                                              'DEBIT'
                                            );
                                          } else {
                                            setValue(
                                              `lineItems.${index}.entryType`,
                                              ''
                                            );
                                          }
                                          onChange(newValue);
                                        }}
                                        disabled={
                                          fieldValues[index]?.entryType ===
                                            'CREDIT' ||
                                          selectedLedgerDecimal === undefined
                                        }
                                        inputProps={{
                                          decimalPlaces:
                                            selectedLedgerDecimal ||
                                            journalEntry?.currencyDecimal,
                                          fixedDecimalScale: true,
                                          allowNegative: false,
                                        }}
                                        onBlur={() => {
                                          if (value === 0) {
                                            setValue(
                                              `lineItems.${index}.entryType`,
                                              ''
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          inputComponent: DecimalFormatCustom,
                                        }}
                                        error={!!error}
                                      />
                                    )}
                                    rules={{
                                      required: fieldValues[index]?.accountId,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={1.8} br>
                                  <Controller
                                    name={`lineItems.${index}.amount`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <LineTextField
                                        {...field}
                                        id={`credit_${index}`}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        number
                                        value={
                                          fieldValues[index]?.entryType ===
                                          'CREDIT'
                                            ? value
                                            : ''
                                        }
                                        onChange={(e) => {
                                          let newValue: any = parseFloat(
                                            e.target.value
                                          ) as number;

                                          if (newValue) {
                                            newValue =
                                              newValue > 0
                                                ? newValue
                                                : fieldValues[index].amount;
                                          }

                                          if (newValue >= 0) {
                                            setValue(
                                              `lineItems.${index}.entryType`,
                                              'CREDIT'
                                            );
                                          } else {
                                            setValue(
                                              `lineItems.${index}.entryType`,
                                              ''
                                            );
                                          }
                                          onChange(newValue);
                                        }}
                                        disabled={
                                          fieldValues[index]?.entryType ===
                                            'DEBIT' ||
                                          selectedLedgerDecimal === undefined
                                        }
                                        inputProps={{
                                          decimalPlaces:
                                            selectedLedgerDecimal ||
                                            journalEntry?.currencyDecimal,
                                          fixedDecimalScale: true,
                                          allowNegative: false,
                                        }}
                                        onBlur={() => {
                                          if (value === 0) {
                                            setValue(
                                              `lineItems.${index}.entryType`,
                                              ''
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          inputComponent: DecimalFormatCustom,
                                        }}
                                        error={!!error}
                                      />
                                    )}
                                    rules={{
                                      required: fieldValues[index]?.accountId,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={2.8} br>
                                  <Controller
                                    name={`lineItems.${index}.entryMemo`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <LineDescription
                                        {...field}
                                        id={`description_${index}`}
                                        size="small"
                                        fullWidth
                                        multiline
                                        number={false}
                                        variant="outlined"
                                        value={
                                          fieldValues[index]?.entryMemo
                                            ? value
                                            : ''
                                        }
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || ''}
                                      />
                                    )}
                                    rules={{
                                      maxLength: 255,
                                      minLength: 0,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={2.4}>
                                  <Controller
                                    name={`lineItems.${index}.entities`}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <JEEntityFilter
                                        {...field}
                                        size="small"
                                        id={`entity_${index}`}
                                        idSelectOnly
                                        options={groupedEntityList}
                                        loading={isLoading}
                                        placeholder="Select Memo Tag(s)"
                                        value={
                                          fieldValues[index]?.entities
                                            ? value
                                            : []
                                        }
                                        setValue={setValue}
                                        setValueField={`lineItems.${index}.entities`}
                                        onChange={(listLenght) => {
                                          setDisplayMemoMessage(
                                            listLenght === 10
                                          );
                                          onChange(value);

                                          setValue(
                                            `lineItems.${index}.entities`,
                                            value
                                          );
                                        }}
                                        error={error}
                                        onDropdownClose={() => {
                                          setDisplayMemoMessage(false);
                                        }}
                                      />
                                    )}
                                    rules={{
                                      required:
                                        fieldValues[index]?.isEntityRequired,
                                    }}
                                    control={control}
                                  />
                                </LineCell>
                                <LineCell item xs={0.5}>
                                  <Label number>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteLine(field.id, index)
                                      }
                                    >
                                      <TrashIcon color="error" />
                                    </IconButton>
                                  </Label>
                                </LineCell>
                              </Line>
                            );
                          })}
                        {journalEntry?.state === 'POSTED' &&
                          journalEntry?.lineItems.map(
                            (item: any, index: number) => {
                              return (
                                <Line key={index}>
                                  <LineCell item xs={0.3} br>
                                    <IndexLabel>{index + 1}</IndexLabel>
                                  </LineCell>
                                  <LineCell item xs={2.5} br readonly>
                                    <JEAccountFilter
                                      size="small"
                                      id={`account_${index}`}
                                      idSelectOnly
                                      value={
                                        item.accountId ? item.accountId : ''
                                      }
                                      handleAddNewAccount={() => {}}
                                      accountList={accountList}
                                      loading={isLoading}
                                      onChange={() => {}}
                                      setValue={() => {}}
                                      setValueField=""
                                      readonly
                                    />
                                  </LineCell>
                                  <LineCell item xs={1.8} br readonly>
                                    <Label number>
                                      {item.entryType === 'DEBIT'
                                        ? NumberFormat(
                                            journalEntry.currencyDecimal,
                                            true
                                          ).format(item.amount)
                                        : ''}
                                    </Label>
                                  </LineCell>
                                  <LineCell item xs={1.8} br readonly>
                                    <Label number>
                                      {item.entryType === 'CREDIT'
                                        ? NumberFormat(
                                            journalEntry.currencyDecimal,
                                            true
                                          ).format(item.amount)
                                        : ''}
                                    </Label>
                                  </LineCell>
                                  <LineCell item xs={2.8} br readonly>
                                    <Label
                                      sx={{
                                        whiteSpace: 'unset',
                                        wordBreak: 'break-all',
                                      }}
                                    >
                                      {item.entryMemo ? item.entryMemo : ''}
                                    </Label>
                                  </LineCell>
                                  <LineCell item xs={2.4} readonly>
                                    <JEEntityFilter
                                      size="small"
                                      id={`entity_${index}`}
                                      options={groupedEntityList}
                                      loading={isLoading}
                                      idSelectOnly
                                      value={
                                        item.entities ? item.entities : null
                                      }
                                      setValue={() => {}}
                                      setValueField=""
                                      onChange={() => {}}
                                      readonly
                                    />
                                  </LineCell>
                                  <LineCell item xs={0.5}></LineCell>
                                </Line>
                              );
                            }
                          )}
                        <LineCell item xs={0.3}>
                          <Label bold></Label>
                        </LineCell>
                        <LineCell item xs={2.5}>
                          <Label bold number>
                            TOTAL
                          </Label>
                        </LineCell>
                        <LineCell item xs={1.8}>
                          <LineTextField
                            id="total_debit"
                            size="small"
                            variant="outlined"
                            fullWidth
                            number
                            disabled={true}
                            value={totalDebit}
                            error={totalError}
                            inputProps={{
                              decimalPlaces:
                                selectedLedgerDecimal ||
                                journalEntry?.currencyDecimal,
                              fixedDecimalScale: true,
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom,
                            }}
                          />
                        </LineCell>
                        <LineCell item xs={1.8}>
                          <LineTextField
                            id="total_credit"
                            size="small"
                            variant="outlined"
                            fullWidth
                            number
                            disabled={true}
                            value={totalCredit}
                            error={totalError}
                            inputProps={{
                              decimalPlaces:
                                selectedLedgerDecimal ||
                                journalEntry?.currencyDecimal,
                              fixedDecimalScale: true,
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom,
                            }}
                          />
                        </LineCell>
                        <LineCell item xs={3}></LineCell>
                        <LineCell item xs={2.5}></LineCell>
                        <LineCell item xs={0.5}></LineCell>
                      </Grid>
                    </LineItemList>
                  </LineItemBox>
                </Grid>
                <Grid item xs={12}>
                  {(isNewJournalEntry || journalEntry?.state === 'DRAFT') && (
                    <>
                      <Button
                        id="btn_add_new_transaction"
                        color="secondary"
                        addSpaceBetweenButtons
                        onClick={handleAddLine}
                        icon={<AddCircleOutlineIcon />}
                        text="Add New Line"
                      />
                      <Button
                        id="btn_remove_all_transactions"
                        color="error"
                        onClick={handleClearAllLines}
                        icon={<TrashIcon />}
                        text="Clear All Lines"
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Controller
                    name="memo"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_memo"
                          label="Memo"
                          aria-describedby="memo"
                          placeholder="Memo"
                          size="small"
                          multiline
                          rows={2}
                          fullWidth
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          error={!!error}
                          disabled={journalEntry?.state === 'POSTED'}
                          helperText={
                            error && 'Memo field cannot exceed 512 characters.'
                          }
                        />
                      );
                    }}
                    rules={{
                      maxLength: 512,
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  {!multiView && (
                    <FormControlCheckbox>
                      <Tooltip
                        title={sendToSOITooltip || ''}
                        placement="bottom"
                      >
                        <FormControlLabel
                          label="Send to SOI"
                          control={
                            <Checkbox
                              id="check_send_to_soi"
                              checked={sendToSOI}
                              value={sendToSOI}
                              onChange={(e) => setSendToSOI(e.target.checked)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          disabled={
                            journalEntry?.state === 'POSTED' ||
                            isSendToSOIDisabled
                          }
                        />
                      </Tooltip>
                    </FormControlCheckbox>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FileSelectorWrapper>
                    <Typography variant="formTitle2">Attachments</Typography>
                    <Controller
                      name="attachments"
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => (
                        <FileSelector
                          title="Drag and drop or browse to files or folders"
                          acceptType=".pdf, .xls, .xlsx, .zip, .doc, .docx, .rtf, .csv, .xlt, .rtc"
                          onUpload={(blob, file) => {
                            onChange(file);
                          }}
                          disabled={Boolean(isLoading)}
                          multiple={true}
                          inline={true}
                          onClear={onChange}
                          preserveFiles={true}
                        />
                      )}
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (!value) {
                            return 'Please select files to upload.';
                          }
                          return true;
                        },
                      }}
                    />
                  </FileSelectorWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {(isNewJournalEntry || journalEntry?.state === 'DRAFT') && (
                    <ButtonBox>
                      <Button
                        id={'btn_journal_entry_cancel'}
                        variant="outlined"
                        onClick={toggleDrawer}
                        text={'Cancel'}
                        color={'secondary'}
                      />
                      {saveButtonOptions.length > 1 && (
                        <SplitButtonSubmit
                          id={'btn_bulk_save_options'}
                          options={saveButtonOptions}
                          handleOptionClick={handleSaveButtonAction}
                          ariaLabelMessage="Select bulk action option"
                          hidden={false}
                        />
                      )}
                      {saveButtonOptions.length === 1 && (
                        <Button
                          id={saveButtonOptions[0].id}
                          variant="contained"
                          onClick={handleSaveButtonAction}
                          text={saveButtonOptions[0].text}
                          color="primary"
                        />
                      )}
                    </ButtonBox>
                  )}
                  {journalEntry?.state === 'POSTED' && (
                    <ButtonBox>
                      <Button
                        id={'btn_journal_close'}
                        variant="outlined"
                        onClick={toggleDrawer}
                        text={'Close'}
                        color={'secondary'}
                      />
                    </ButtonBox>
                  )}
                </Grid>
                {journalEntry?.currentAttachments &&
                  journalEntry?.currentAttachments.length > 0 && (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <AttachmentButtonBox>
                        <Button
                          id="btn_show_attachments"
                          variant="outlined"
                          text={
                            toggleAttachmentListView
                              ? 'Hide Existing'
                              : 'Show Existing'
                          }
                          color={'secondary'}
                          onClick={() =>
                            setToggleAttachmentListView(
                              !toggleAttachmentListView
                            )
                          }
                        />
                      </AttachmentButtonBox>
                    </Grid>
                  )}

                <Grid item sm={6} md={6} lg={6}></Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {toggleAttachmentListView &&
                    journalEntry?.currentAttachments &&
                    journalEntry?.currentAttachments.length > 0 &&
                    journalEntry?.currentAttachments?.map((a) => {
                      return (
                        <AttachmentListBox>
                          <ActionLink
                            id={`link_${a.documentId}`}
                            onClick={() =>
                              handleDownloadAttachment(a.documentMemo!)
                            }
                          >
                            <DocumentLinkBox>
                              <FilePresentIcon />
                              {a.documentMemo}
                            </DocumentLinkBox>
                          </ActionLink>
                          <IconButton
                            aria-label="delete attachment"
                            color="primary"
                            title="Delete file attachment"
                            id={a.documentId}
                            onClick={() => onDeleteAttachment(a.documentMemo!)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </AttachmentListBox>
                      );
                    })}
                </Grid>
              </Grid>
              <ConfirmationDialog
                open={showClearAllConfirmation}
                onClose={handleCancelClearAll}
                id="delete_lines_confirmation"
                actions={[
                  {
                    label: 'Delete Permanently',
                    onClick: () => handleConfirmClearAll(),
                    id: 'btn_delete_transaction',
                    variant: 'contained',
                    color: 'primary',
                  },
                  {
                    label: 'Cancel',
                    onClick: handleCancelClearAll,
                    id: 'btn_cancel_delete',
                    variant: 'outlined',
                    color: 'error',
                  },
                ]}
                content="Are you sure you want to remove all the lines? This action can not be undone."
                title="Clear All Lines?"
              />
            </PanelBoxExpanded>
            {multiView && (
              <HorizontalBox
                addTopShadow
                attachToBottom={true}
                hidden={false}
                fullWidth
              >
                <LinkBox>
                  <LinkButton
                    id="btn_previous_je"
                    variant="text"
                    color="primary"
                    name="View Previous"
                    disableElevation
                    disabled={multiView.currentIndex === 0}
                    onClick={() => {
                      multiView.previous();
                    }}
                    startIcon={<ArrowBackIosRoundedIcon />}
                  >
                    View Previous
                  </LinkButton>
                  <Title>
                    {multiView.currentIndex + 1} of {multiView.totalCount}
                  </Title>
                  <LinkButton
                    id="btn_next_je"
                    variant="text"
                    color="primary"
                    name="View Next"
                    disableElevation
                    disabled={
                      multiView.currentIndex === multiView.totalCount - 1
                    }
                    onClick={() => {
                      multiView.next();
                    }}
                    endIcon={<ArrowForwardIosIcon />}
                    sx={{ left: '10px' }}
                  >
                    View Next
                  </LinkButton>
                </LinkBox>
              </HorizontalBox>
            )}
          </form>
          <StyledBackdrop open={Boolean(isLoading)} />
          <ProgressPanel
            id={'progress_journal_entry_panel'}
            showProgress={Boolean(isLoading)}
            text={
              isLoading
                ? isLoading === LoadingStatus.Downloading
                  ? `${isLoading} attachment...`
                  : `${isLoading} journal entry...`
                : ''
            }
          />
        </StyledLateralBox>
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_journal_entry_confirmation"
        actions={[
          {
            label: 'Continue Editing',
            onClick: keepDrawerOpen,
            id: 'btn_journal_entry_keep',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_journal_entry_cancel_discard',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={unsavedChangesContent || 'Unsaved changes will be lost'}
        title={unsavedChangesTitle || 'You have Unsaved Changes'}
      />
      <ConfirmationDialog
        open={showUpdateLedgerConfirmation}
        onClose={keepDrawerOpen}
        id="update_ledger_confirmation"
        actions={[
          {
            label: 'Cancel',
            onClick: updateLedgerCancel,
            id: 'btn_update_ledger_cancel',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: 'Continue',
            onClick: updateLedgerConfirm,
            id: 'btn_update_ledger_continue',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        content="When changing a journal entry from one general ledger to another, be aware that the journal entry number will reset. Please exercise caution and double-check your entries before proceeding to ensure accurate record keeping. 
        Would you like to continue? "
        title="Journal entry ledger update"
      />
      <DeleteConfirmationDialog
        open={showDeleteAttachmentConfirmation}
        onClose={() => {}}
        id="delete_attachment_confirmation"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        content={
          <Box component="span">
            This action will remove this attachment. This action is permanent
            and cannot be undone.
          </Box>
        }
        title="Are you sure you want to delete?"
        actionType={`DELETE ${selectedDeleteAttachment}`}
      />
      <JournalEntryPostDialog
        open={showPostDialog}
        onPostCancel={onPostCancel}
        onPostComplete={onPostComplete}
        onClose={() => {}}
        ids={journalEntriesToPost}
        postAction={postAction}
      />
      <ConfirmationDialog
        open={showAssociatedInvTransactionNotice}
        isTextContent={false}
        id="create_investment_transaction_confirmation"
        onClose={() => {
          setShowAssociatedInvTransactionNotice(false);
          closeDrawer();
          onSuccessfulPostClose?.();
        }}
        actions={[
          {
            label: 'Review Proposed Investment Transaction Adjustments',
            onClick: reviewSuggestedInvestmentTransaction,
            id: 'btn_save_investment_transaction',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        content={
          'A change has been made to a Journal Entry with an associated Investment Transaction.'
        }
        title="Notice"
        showXbutton={true}
      />
      {!!selectedAccount?.type && (
        <AccountDetails
          selectedAccount={selectedAccount}
          onDetailClose={onAccountDetailsPanelClose}
          fetchAllAccounts={() => {}}
          fundId={fundId || selectedFundId}
        />
      )}
      {!!selectedInvestmentTransaction?.type && (
        <InvestmentTransactionDetails
          selectedInvestmentTransaction={selectedInvestmentTransaction}
          onDetailClose={onInvestmentTransactionPanelClose}
          fetchAllInvestmentTransactions={() => {}}
          setSelectedInvestmentTransaction={setSelectedInvestmentTransaction}
        />
      )}
    </>
  );
};
